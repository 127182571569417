import { useEffect, useMemo } from 'react'
import cls from 'classnames'
import { Link, useLocation } from 'react-router-dom'
import { AccountTypeNames } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import {
  getRelatedAccountsRequest,
  logoutRequest,
  showModalAction,
  useAppDispatch,
  useAppSelector
} from 'store'
import { EAvatarSize, ENotificationBadgeSize } from 'enums'
import { getProfileLink } from 'utils'
import { useAdaptiveLayout, useDialog, useInterruptionConfirm } from 'App/hooks'
import {
  AvatarContainer,
  EModalComponents,
  HeaderMedCloud,
  HeaderNotificationsContainer,
  RelatedAccountsListContainer,
  UserProfileAdministrationOnlineContainer
} from 'App/containers'
import { AccountName, AccountType, NotificationBadge, Popover, TruncatedText } from 'App/components'
import { subscriptionAndPaymentsNestedRoutes } from 'App/App.config'
import { Nav, TNavClasses } from 'App/components/Layout/components/Nav'
import { CREATE_BUSINESS_ACCOUNT_MODAL_WIDTH, SUPPORT_EMAIL } from 'globalConstants'
import { ReactComponent as MenuArrowIcon } from 'assets/icons/ChevronDown.svg'
import { ReactComponent as MenuIcon } from 'assets/icons/Menu.svg'
import { ReactComponent as MenuCrossIcon } from 'assets/icons/Cross.svg'
import { ReactComponent as LogoutIcon } from 'assets/icons/Logout.svg'
import { ReactComponent as SettingsIcon } from 'assets/icons/GearSetting.svg'
import { ReactComponent as PaymentIcon } from 'assets/icons/Payment.svg'
import { ReactComponent as AdministrationIcon } from 'assets/icons/Administration.svg'
import { ReactComponent as AccountArrowRightOutlineIcon } from 'assets/icons/AccountArrowRightOutline.svg'

import { Subscription } from '../Subscription'
import { SupportNavItem } from '../../../components/Layout/components/SupportNavItem'
import { useNavConfig, useNavItems } from '../../../components/Layout/components/Nav/nav.constants'

import styles from './HeaderMenu.module.scss'

const SUBSCRIPTION_PATH = '/subscription-and-payments/subscription'
const classes: TNavClasses['classes'] = {
  nav: styles.nav,
  link: styles.navLink,
  iconWrapper: styles.navIcon,
  badge: styles.badge
}

export const HeaderMenu = () => {
  const {
    open: menuState,
    toggle: toggleMenuState,
    setOpen: setMenuState,
    close: setMenuClose
  } = useDialog()

  const { pathname } = useLocation()

  const { isMobile, isDesktop } = useAdaptiveLayout()

  const dispatch = useAppDispatch()

  const {
    firstName = '',
    lastName = '',
    companyName,
    id,
    displayUserName
  } = useAppSelector((state) => state.global.accountData) ?? {}
  const wsStatus = useAppSelector((state) => state.global.webSocketConnected)
  const type = useAppSelector((state) => state.global.accountData?.type?.name)
  const role = useAppSelector((state) => state.global.accountData?.role?.name)
  const email = useAppSelector((state) => state.global.accountData?.email)
  const relatedAccountsIdList = useAppSelector((state) => state.userProfile.relatedAccounts.ids)
  const connectorNotification = useAppSelector(
    (state) => state.notifications.general.connector.totalUnreadCount
  )
  const relatedNotifications = useAppSelector(
    (state) => state.userProfile.relatedAccounts.notifications.total
  )
  const isFolded = useAppSelector((state) => state.calls.isFolded)
  const hasActiveCall = useAppSelector(
    (state) =>
      (!!state.calls.answer.createCall && !state.calls.answer.isComingCall) ||
      state.organizations.activeVoiceRoom
  )

  const { headerNav } = useNavConfig()

  const list = useNavItems({ classes, list: headerNav })

  const { t } = useTranslation()

  const isBusinessAccount = type === AccountTypeNames.BUSINESS

  const showSwitchAccounts = isMobile && relatedAccountsIdList.length

  const showPageNavigation = isMobile

  const showSupportChat = isMobile && email !== SUPPORT_EMAIL

  const logoutOnClick = () => {
    toggleMenuState()
    dispatch(logoutRequest())
  }

  const handleLogoutClick = useInterruptionConfirm(logoutOnClick, 'logout')

  const subscriptionRoute = useMemo(
    () => subscriptionAndPaymentsNestedRoutes.find(({ path }) => path === SUBSCRIPTION_PATH),
    []
  )
  const showSubscriptionLink = useMemo(() => {
    if (subscriptionRoute && role) {
      return subscriptionRoute.allowedBusinessRoles?.includes(role)
    }

    if (subscriptionRoute && type) {
      return subscriptionRoute?.allowedAccountTypes?.includes(type)
    }

    return false
  }, [type, role, subscriptionRoute])

  const onMedCloudClick = () => {
    setMenuState(false)
  }

  useEffect(
    () => () => {
      setMenuState(false)
    },
    [pathname, setMenuState]
  )

  useEffect(() => {
    if (menuState) {
      dispatch(getRelatedAccountsRequest())
    }
  }, [dispatch, menuState])

  useEffect(() => {
    if (hasActiveCall && !isFolded) {
      setMenuState(false)
    }
  }, [hasActiveCall, isFolded, setMenuState])

  if (!type || !id || !displayUserName) {
    return null
  }

  const avatarBaseProps = {
    online: wsStatus,
    type,
    size: EAvatarSize.SM,
    hasTooltip: false,
    userId: id,
    lastName,
    firstName,
    companyName,
    displayUserName
  }

  const handleCreateBusinessAccount = () => {
    setMenuClose()
    dispatch(
      showModalAction({
        modalType: EModalComponents.CREATE_BUSINESS_ACCOUNT_DIALOG,
        modalTitle: null,
        modalWidth: CREATE_BUSINESS_ACCOUNT_MODAL_WIDTH
      })
    )
  }

  const handleSwitchAccount = () => {
    setMenuClose()
    dispatch(
      showModalAction({
        modalType: EModalComponents.RELATED_ACCOUNTS_DIALOG,
        modalTitle: t('modal.switchAccount.title'),
        modalWidth: 'unset'
      })
    )
  }

  return (
    <div className={styles.root}>
      {!(isMobile && menuState) && <HeaderNotificationsContainer />}

      <div className={cls(styles.wrapper, { [styles.wrapperOpen]: menuState })}>
        {isDesktop && (
          <div className={styles.userInfo}>
            <div className={styles.nameContainer}>
              <AccountType type={type} className={styles.accountType} />
              <TruncatedText text={`${displayUserName} `} rows="two" />
              {isBusinessAccount && <UserProfileAdministrationOnlineContainer />}
            </div>
          </div>
        )}

        <Popover
          visible={menuState}
          onVisibleChange={!isMobile ? toggleMenuState : undefined}
          placement="bottomRight"
          trigger="click"
          overlayClassName={styles.popover}
          arrow={false}
          content={
            <div className={styles.menu}>
              {!isDesktop && (
                <div className={styles.menuCloudWrapper} onClick={onMedCloudClick}>
                  <HeaderMedCloud variant={isMobile ? 'cloud' : 'menu'} />
                </div>
              )}
              <Link
                to={getProfileLink(id)}
                onClick={setMenuClose}
                className={styles.menuHeaderWrapper}
              >
                <AvatarContainer {...avatarBaseProps} />
                <div className={styles.menuHeaderContent}>
                  <AccountName
                    displayUserName={displayUserName}
                    type={type}
                    classes={{ root: styles.accountNameRoot }}
                  />

                  <span className={styles.menuMyProfileText}>
                    {t('headerMenu.profileLabel', { context: role })}
                  </span>
                </div>
              </Link>
              {!isMobile && (
                <RelatedAccountsListContainer
                  type={type}
                  onCreateBusinessAccount={handleCreateBusinessAccount}
                />
              )}

              <Subscription role={role} />
              {showPageNavigation && <Nav list={list} classes={classes} />}
              <div className={styles.section}>
                {isBusinessAccount && (
                  <Link className={styles.menuItem} to="/organization/administration">
                    <AdministrationIcon className={styles.menuItemIcon} />{' '}
                    {t('headerMenu.links.administration')}
                  </Link>
                )}
                {showSubscriptionLink && (
                  <Link className={styles.menuItem} to={SUBSCRIPTION_PATH}>
                    <PaymentIcon className={styles.menuItemIcon} />{' '}
                    {t('headerMenu.links.subscription')}
                  </Link>
                )}
                {showSwitchAccounts && (
                  <button className={styles.menuItem} onClick={handleSwitchAccount}>
                    <AccountArrowRightOutlineIcon className={styles.menuItemIcon} />
                    {t('headerMenu.links.switchOrCreateAccount')}
                    {relatedNotifications > 0 && (
                      <div className={styles.menuItemBadge}>
                        <NotificationBadge
                          count={relatedNotifications}
                          className={styles.menuItemBadgeContent}
                        />
                      </div>
                    )}
                  </button>
                )}
              </div>
              {showSupportChat && (
                <div className={styles.section}>
                  <SupportNavItem
                    classes={{
                      nav: cls(styles.nav, styles.menuItem),
                      link: styles.navLink,
                      iconWrapper: styles.navIcon,
                      badge: styles.badge,
                      group: styles.navGroup
                    }}
                  />
                </div>
              )}
              <div className={styles.section}>
                <Link className={styles.menuItem} to="/settings">
                  <SettingsIcon className={styles.menuItemIcon} /> {t('headerMenu.links.settings')}
                </Link>
                <div className={styles.menuItem} onClick={handleLogoutClick}>
                  <LogoutIcon className={styles.menuItemIcon} /> {t('headerMenu.links.logOut')}
                </div>
              </div>
            </div>
          }
        >
          <div className={styles.avatarWrapper}>
            {!isMobile && (
              <>
                <AvatarContainer
                  {...avatarBaseProps}
                  onlineBorderCssRootPropertyValue="--palette-primary"
                  border={true}
                />
                <NotificationBadge
                  border
                  variant="dot"
                  count={relatedNotifications}
                  className={styles.avatarWrapperBadge}
                  borderCssRootPropertyValue="--palette-white"
                />

                <MenuArrowIcon className={styles.avatarIcon} />
              </>
            )}
            {isMobile &&
              (menuState ? (
                <MenuCrossIcon className={styles.avatarIcon} onClick={setMenuClose} />
              ) : (
                <div className={styles.burger}>
                  <MenuIcon className={styles.burgerIcon} onClick={() => setMenuState(true)} />
                  <NotificationBadge
                    variant="dot"
                    count={connectorNotification + relatedNotifications}
                    className={styles.burgerNotification}
                    size={ENotificationBadgeSize.SM}
                    border={true}
                    borderCssRootPropertyValue="--palette-primary"
                  />
                </div>
              ))}
          </div>
        </Popover>
      </div>
    </div>
  )
}
