import { ErrorCodesEnum } from '@medentee/enums'
import { AxiosError } from 'axios'
import isArray from 'lodash/isArray'
import { TOptions } from 'i18next'

import { TError } from 'store'

import i18n from '../i18n'
import Resources from '../../typings/resources'

export type TResponseError = NonNullableBy<Partial<AxiosError>, 'message'> | null

type TErrorCodesUnion = keyof Resources['errors']['serverError']

const getParameters = (payload: Record<string, unknown>): TOptions => {
  const { message, code: _code, status: _status, ...parameters } = payload

  return {
    ...parameters,
    defaultValue: message ?? i18n.t('serverError.DEFAULT_ERROR_MESSAGE', { ns: 'errors' })
  }
}

export const parseError = (error: TResponseError) => {
  if (error) {
    console.error(error)
    if (error.response && error.response.data) {
      if (error.response.data.payload && error.response.data.payload.message) {
        return i18n.t(`serverError.${error.response.data.payload.code as TErrorCodesUnion}`, {
          ns: 'errors',
          ...getParameters(error.response.data.payload)
        })
      }

      if (error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          return i18n.t(`serverError.${error.response.data.code as TErrorCodesUnion}`, {
            ns: 'errors',
            ...getParameters(error.response.data)
          })
        }

        if (isArray(error.response.data.message)) {
          return error.response.data.message.join('; ')
        }
      }
    }

    if (error.code) {
      return i18n.t(`serverError.${error.code as TErrorCodesUnion}`, {
        ns: 'errors',
        ...getParameters(error)
      })
    }

    return i18n.t('serverError.DEFAULT_ERROR_MESSAGE', { ns: 'errors' })
  }

  return null
}

export const isMatchErrorCode = (e: TError, code: ErrorCodesEnum | ErrorCodesEnum[]) =>
  isArray(code)
    ? code.includes(e?.response?.data.code)
    : e?.response?.data.code === ErrorCodesEnum[code]
