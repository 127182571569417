import { useCallback, useEffect } from 'react'
import { Field, Form, useFormState, useForm } from 'react-final-form'
import { Col, Row } from 'antd'
import { GetCountryQueryModeEnum } from '@medentee/enums'
import isBefore from 'date-fns/isBefore'
import isArray from 'lodash/isArray'
import { useTranslation } from 'react-i18next'

import {
  getCitiesRequest,
  getCountriesRequest,
  TCity,
  TCountry,
  useAppDispatch,
  useAppSelector
} from 'store'
import {
  MAX_LENGTH_100,
  MAX_LENGTH_5000,
  MAX_LENGTH_300,
  MAX_LENGTH_500,
  MIN_LENGTH_1,
  MIN_PICKER_DATE
} from 'globalConstants'
import { validation } from 'utils/validation'
import { TextField, RangeDatePicker, Select, DatePicker } from 'App/components/common/Fields'
import { Button } from 'App/components/common/Button'
import { Switch } from 'App/components/common/Switch'
import { useUnsavedDataPrompt } from 'App/hooks/useUnsavedDataPrompt'
import { longDate } from 'utils/formats'
import { TextFieldSkeleton } from 'App/components/common/Skeletons/TextFieldSkeleton'

import styles from './EventInfoForm.module.scss'

export type TEventInfoFormValues = {
  title: string
  dates: string | Date | (string | Date | undefined)[]
  country?: TCountry | null
  city?: TCity | null
  isOneDay: boolean

  location?: string
  headline?: string
  about?: string
}

export type TEventInfoFormCreate = {
  mode: 'create'
  onCancel: () => void
}

export type TEventInfoFormEdit = {
  mode: 'edit'
  id: string
  loading: boolean

  initialValues?: TEventInfoFormValues
}

export type TEventInfoFormProps = {
  processing: boolean
  onSubmit: (values: TEventInfoFormValues) => void

  shouldDisableAfterSubmitSucceeded?: boolean
} & (TEventInfoFormCreate | TEventInfoFormEdit)

const SpyOnFormChanged = ({
  onChange,
  prompt
}: {
  onChange: (value: boolean) => void
  prompt: JSX.Element
}) => {
  const dispatch = useAppDispatch()

  const { pristine, values, dirtySinceLastSubmit, submitSucceeded } =
    useFormState<Partial<TEventInfoFormValues>>()
  const { change } = useForm<TEventInfoFormValues>()

  useEffect(() => {
    values.country?.code && dispatch(getCitiesRequest({ countryCode: values.country?.code }))

    if (values.city?.countryCode !== values.country?.code) {
      change('city', undefined)
    }
  }, [change, dispatch, values.city?.countryCode, values.country?.code])

  useEffect(() => {
    let dates = values.dates

    if (values.isOneDay && isArray(values.dates)) {
      dates = values.dates[0]
    }

    if (!values.isOneDay && values.dates && !isArray(values.dates)) {
      dates = [new Date(values.dates), undefined]
    }

    change('dates', dates)
  }, [change, dispatch, values.dates, values.isOneDay])

  useEffect(() => {
    onChange(submitSucceeded ? dirtySinceLastSubmit : !pristine)
  }, [pristine, onChange, submitSucceeded, dirtySinceLastSubmit])

  return prompt
}

export const EventInfoForm = ({
  onSubmit,
  shouldDisableAfterSubmitSucceeded = true,
  ...props
}: TEventInfoFormProps) => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const { countries, cities } = useAppSelector((state) => state.misc)

  const isEdit = props.mode === 'edit'

  const { onCancel } = (!isEdit && props) || {}
  const { initialValues, loading = false } = (isEdit && props) || {}

  const { ignore, unIgnore, prompt, setFormChanged } = useUnsavedDataPrompt()

  const handleCancel = () => {
    ignore()
    onCancel && onCancel()
  }

  const handleSubmit = (values: TEventInfoFormValues) => {
    ignore()
    onSubmit(values)
  }

  const handleSpyChange = (value: boolean) => {
    unIgnore()
    setFormChanged(value)
  }

  useEffect(() => {
    dispatch(getCountriesRequest({ mode: GetCountryQueryModeEnum.ALL }))
  }, [dispatch])

  const disabledDate = useCallback((date) => isBefore(date, MIN_PICKER_DATE), [])

  return (
    <Form onSubmit={handleSubmit} initialValues={initialValues}>
      {({
        handleSubmit: onSubmitClick,
        submitting,
        valid,
        values,
        dirty,
        dirtySinceLastSubmit,
        submitSucceeded
      }) => (
        <Row gutter={[0, 50]}>
          <SpyOnFormChanged onChange={handleSpyChange} prompt={prompt} />

          <Col xs={24}>
            <Row gutter={[24, 24]}>
              <Col xs={24}>
                <TextFieldSkeleton loading={loading}>
                  <Field
                    name="title"
                    validate={validation.composeValidators(
                      validation.onlySpaces(),
                      validation.required(),
                      validation.minLength(MIN_LENGTH_1),
                      validation.maxLength(MAX_LENGTH_100)
                    )}
                  >
                    {({ input, meta }) => (
                      <TextField
                        {...input}
                        topLabel={t('events.detailsForm.title')}
                        valueLengthMax={MAX_LENGTH_100}
                        invalid={meta.touched && meta.invalid}
                        error={meta.error}
                      />
                    )}
                  </Field>
                </TextFieldSkeleton>
              </Col>
              <Col xs={24}>
                <Row gutter={[24, 24]} align="bottom">
                  <Col xs={24} md={12}>
                    <TextFieldSkeleton loading={loading}>
                      <Field
                        name="dates"
                        validate={validation.composeValidators(
                          validation.required(),
                          validation.minDate(MIN_PICKER_DATE)
                        )}
                      >
                        {({ input, meta }) =>
                          values.isOneDay ? (
                            <DatePicker
                              {...input}
                              topLabel={t('events.detailsForm.dates')}
                              picker="date"
                              defaultValue={input.value}
                              format={longDate}
                              placeholder={t('events.detailsForm.datePlaceholder_one')}
                              disabledDate={disabledDate}
                              invalid={meta.touched && meta.invalid}
                              error={meta.error}
                            />
                          ) : (
                            <RangeDatePicker
                              {...input}
                              topLabel={t('events.detailsForm.dates')}
                              picker="date"
                              defaultValue={input.value}
                              format={longDate}
                              placeholder={t('events.detailsForm.datePlaceholder_other', {
                                returnObjects: true
                              })}
                              disabledDate={disabledDate}
                              invalid={meta.touched && meta.invalid}
                              error={meta.error}
                            />
                          )
                        }
                      </Field>
                    </TextFieldSkeleton>
                  </Col>
                  <Col xs={24} md={12}>
                    <TextFieldSkeleton loading={loading}>
                      <Field name="isOneDay">
                        {({ input }) => (
                          <Switch
                            {...input}
                            label={t('events.detailsForm.oneDayEvent')}
                            defaultChecked={initialValues?.isOneDay}
                            classes={{ root: styles.switch }}
                          />
                        )}
                      </Field>
                    </TextFieldSkeleton>
                  </Col>
                </Row>
              </Col>
              <Col xs={24} md={12}>
                <TextFieldSkeleton loading={loading}>
                  <Field name="country">
                    {({ input, meta }) => (
                      <Select
                        {...input}
                        topLabel={t('events.detailsForm.country')}
                        options={countries}
                        labelKey="countryName"
                        valueKey="code"
                        isClearable={true}
                        menuPosition="fixed"
                        invalid={meta.touched && meta.invalid}
                        error={meta.error}
                      />
                    )}
                  </Field>
                </TextFieldSkeleton>
              </Col>
              <Col xs={24} md={12}>
                <TextFieldSkeleton loading={loading}>
                  <Field name="city">
                    {({ input, meta }) => (
                      <Select
                        {...input}
                        topLabel={t('events.detailsForm.city')}
                        options={cities}
                        labelKey="cityName"
                        valueKey="id"
                        isClearable={true}
                        invalid={meta.touched && meta.invalid}
                        error={meta.error}
                      />
                    )}
                  </Field>
                </TextFieldSkeleton>
              </Col>
              <Col xs={24}>
                <TextFieldSkeleton loading={loading}>
                  <Field
                    name="location"
                    validate={validation.composeValidators(
                      validation.onlySpaces(),
                      validation.maxLength(MAX_LENGTH_300)
                    )}
                  >
                    {({ input, meta }) => (
                      <TextField
                        {...input}
                        topLabel={t('events.detailsForm.location')}
                        valueLengthMax={MAX_LENGTH_300}
                        invalid={meta.touched && meta.invalid}
                        error={meta.error}
                      />
                    )}
                  </Field>
                </TextFieldSkeleton>
              </Col>
              {isEdit && (
                <>
                  <Col xs={24}>
                    <TextFieldSkeleton loading={loading} variant="textArea">
                      <Field
                        name="headline"
                        validate={validation.composeValidators(
                          validation.onlySpaces(),
                          validation.maxLength(MAX_LENGTH_500)
                        )}
                      >
                        {({ input, meta }) => (
                          <TextField
                            {...input}
                            topLabel={t('events.detailsForm.headline')}
                            multiline
                            rows={4}
                            valueLengthMax={MAX_LENGTH_500}
                            invalid={meta.touched && meta.invalid}
                            error={meta.error}
                          />
                        )}
                      </Field>
                    </TextFieldSkeleton>
                  </Col>
                  <Col xs={24}>
                    <TextFieldSkeleton loading={loading} variant="textArea">
                      <Field
                        name="about"
                        validate={validation.composeValidators(
                          validation.onlySpaces(),
                          validation.maxLength(MAX_LENGTH_5000)
                        )}
                      >
                        {({ input, meta }) => (
                          <TextField
                            {...input}
                            topLabel={t('events.detailsForm.about')}
                            multiline
                            rows={4}
                            valueLengthMax={MAX_LENGTH_5000}
                            invalid={meta.touched && meta.invalid}
                            error={meta.error}
                          />
                        )}
                      </Field>
                    </TextFieldSkeleton>
                  </Col>
                </>
              )}
            </Row>
          </Col>

          <Col xs={24}>
            <Row gutter={[12, 20]} justify="center" className={styles.actions}>
              {!isEdit && (
                <Col>
                  <Button variant="outlined" onClick={handleCancel}>
                    {t('events.detailsForm.cancelButton')}
                  </Button>
                </Col>
              )}
              <Col>
                <Button
                  onClick={onSubmitClick}
                  disabled={
                    !valid ||
                    !dirty ||
                    (shouldDisableAfterSubmitSucceeded && submitSucceeded && !dirtySinceLastSubmit)
                  }
                  loading={submitting || props.processing}
                >
                  {isEdit
                    ? t('events.detailsForm.submitButton_edit')
                    : t('events.detailsForm.submitButton')}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </Form>
  )
}
