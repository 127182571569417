import { useEffect, useRef } from 'react'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import { Field, Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'

import { validation } from 'utils'
import { MAX_LENGTH_120 } from 'globalConstants'
import { Button, Checkbox, ETextFieldSize, Spinner, TextField } from 'App/components'
import { EModalComponents } from 'App/containers/ModalRoot'
import { TUserProfileProfessionProps, UserProfileNewProfessionContainer } from 'App/containers'
import { ReactComponent as CrossIcon } from 'assets/icons/Cross.svg'
import { ReactComponent as TrashIcon } from 'assets/icons/Trash.svg'
import { ReactComponent as PlusIcon } from 'assets/icons/Plus.svg'

import styles from '../UserProfileNewProfession/UserProfileNewProfession.module.scss'

type TOnAddSpecializationProps = {
  specialization: string
}

export const UserProfileProfession = ({
  id,
  professionId,
  isDefault,
  isActive,
  specializations,
  editProfession,
  showModal,
  addSpecialization,
  deleteSpecialization,
  specializationsLoading,
  accountType
}: TUserProfileProfessionProps) => {
  const listRef = useRef<HTMLUListElement>(null)
  const listScrollPositionRef = useRef(0)
  const lastActionRef = useRef<'ADDED' | 'REMOVED' | null>(null)

  const { t } = useTranslation()

  const onRemoveProfession = () => {
    showModal({
      modalTitle: t('modal.deleteUserProfessionConfirm.title', { context: accountType }),
      modalType: EModalComponents.DELETE_USER_PROFILE_PROFESSION_CONFIRM,
      modalProps: {
        professionId: id,
        specializationsPresent: specializations.length > 0
      }
    })
  }

  const onChangeActive = (e: CheckboxChangeEvent) => {
    editProfession({
      active: e.target.checked,
      id,
      professionId
    })
  }

  const onAddSpecialization = ({ specialization }: TOnAddSpecializationProps) => {
    addSpecialization({
      professionId: id,
      specialization
    })
    lastActionRef.current = 'ADDED'
  }

  const onDeleteSpecialization = (specializationId: string) => {
    deleteSpecialization({
      professionId: id,
      specializationId
    })
    lastActionRef.current = 'REMOVED'
    listScrollPositionRef.current = listRef.current?.scrollTop ?? 0
  }

  useEffect(() => {
    if (listRef.current) {
      if (lastActionRef.current === 'ADDED') {
        listRef.current.scrollTop = listRef.current.scrollHeight
      }
      if (lastActionRef.current === 'REMOVED' && listScrollPositionRef.current) {
        listRef.current.scrollTop = listScrollPositionRef.current
      }
    }

    lastActionRef.current = null
  }, [specializations.length])

  return (
    <div className={styles.root}>
      <Checkbox
        checked={isActive}
        disabled={isDefault}
        disabledLabel={false}
        label={
          isDefault
            ? t('userProfile.professions.selectTopLabel_MAIN_PROFESSIONAL_requried')
            : t('userProfile.professions.selectTopLabel_PROFESSIONAL_requried')
        }
        classes={{ root: styles.checkbox }}
        onChange={onChangeActive}
      />
      <UserProfileNewProfessionContainer id={id} isEditing={true} />
      <div className={styles.specWrapperSpecializations}>
        <div className={styles.inputWrapper}>
          <Form onSubmit={onAddSpecialization}>
            {({ handleSubmit, form: { getState, restart } }) => (
              <form
                onSubmit={async (event) => {
                  await handleSubmit(event)
                  restart()
                }}
              >
                <Field
                  name="specialization"
                  validate={validation.composeValidators(
                    validation.onlySpaces(),
                    validation.required(),
                    validation.maxLength(MAX_LENGTH_120)
                  )}
                >
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      topLabel={
                        <span className={styles.inputLabel}>
                          {t('userProfile.specializations.selectTopLabel')}
                        </span>
                      }
                      size={ETextFieldSize.SM}
                      invalid={meta.touched && meta.invalid}
                      error={meta.error}
                      placeholder={t('userProfile.specializations.placeholder')}
                      valueLengthMax={MAX_LENGTH_120}
                      endAdornment={
                        <Button
                          type="submit"
                          className={styles.addButton}
                          disabled={getState().invalid || specializationsLoading}
                          icon={<PlusIcon />}
                          variant="text"
                        >
                          {t('userProfile.specializations.addButton')}
                        </Button>
                      }
                    />
                  )}
                </Field>
              </form>
            )}
          </Form>
        </div>
        {specializations.length > 0 &&
          ((specializationsLoading && <Spinner contentCenter={true} />) || (
            <ul ref={listRef} className={styles.specList}>
              {specializations.map((item) => {
                const onClick = () => onDeleteSpecialization(item.id)

                return (
                  <li key={item.id} className={styles.specItem}>
                    <p className={styles.specName}>{item.name}</p>
                    <TrashIcon className={styles.specIcon} onClick={onClick} />
                  </li>
                )
              })}
            </ul>
          ))}
      </div>
      {!isDefault && (
        <button className={styles.removeBtn} onClick={onRemoveProfession}>
          {t('userProfile.specializations.removeButton')}
          <CrossIcon className={styles.removeBtnIcon} />
        </button>
      )}
    </div>
  )
}
