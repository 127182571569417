import { memo, useMemo } from 'react'
import cls from 'classnames'
import { Skeleton } from 'antd'
import {
  MessageIconTypeEnum,
  MessageTypeEnum,
  MeetingStatusEnum,
  ChatTypeEnum
} from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { EAvatarSize, EChatViewType, ESendMessageStatus } from 'enums'
import { getMapComponent } from 'utils'
import { getExtensionWithoutDot } from 'utils/files'
import {
  MESSAGES,
  Spinner,
  TChatMessageWrapperReturnProps,
  ConnectionLabelIcon,
  ChatMessageReactionList
} from 'App/components'
import {
  AvatarContainer,
  ChatMessageKebabMenuContainer,
  TChatMessageContainerViewProps,
  TVideoReadinessStatusSize,
  VideoReadinessStatus
} from 'App/containers'
import { useAdaptiveLayout } from 'App/hooks'
import { IMAGE_WHITELIST } from 'globalConstants'

import { ChatMessageWrapper } from './ChatMessageWrapper'
import { ChatMessageTime } from './ChatMessageTime'
import { ChatMessageAuthor } from './ChatMessageAuthor'
import { ChatMessagePlaceholder } from './ChatMessagePlaceholder'
import { ChatMessageCaseLabel } from './ChatMessageCaseLabel'
import styles from './ChatMessage.module.scss'

export type TChatMessageProps = TChatMessageContainerViewProps

const ChatMessageView = ({
  loading,
  yourMessage,
  showDate,
  editing,
  recipientRead,
  chatId,
  chatRoomType,
  id,
  type: messageType,
  case: caseData,
  edited,
  createdAt,
  file,
  groupStart,
  groupEnd,
  iconType,
  redirectToCaseFromChatRequest,
  originalSender,
  isOriginalSender,
  meeting,
  accountId,
  isMessageOutgoing,
  caseView,
  sendStatus = ESendMessageStatus.SENT,
  chatViewType,
  pin,
  quoteMessage,
  ...props
}: TChatMessageProps) => {
  const { isMobile, isDesktop, isTablet } = useAdaptiveLayout()

  const { t } = useTranslation()

  const isOrganizationChat = chatRoomType === ChatTypeEnum.ORGANIZATION
  const isCommunityChannel = chatRoomType === ChatTypeEnum.COMMUNITY_CHANNEL
  const isCommunityNews = chatRoomType === ChatTypeEnum.COMMUNITY_NEWS
  const isEventChannel = chatRoomType === ChatTypeEnum.EVENT_CHANNEL
  const isEventNews = chatRoomType === ChatTypeEnum.EVENT_NEWS
  const isCaseGroupChat = chatRoomType === ChatTypeEnum.CASE_GROUP
  const isGroupChat = chatRoomType === ChatTypeEnum.GROUP
  const isDialogueChat = chatRoomType === ChatTypeEnum.DIALOGUE
  const isCaseView = chatViewType === EChatViewType.CASE

  const isMessageTypeFile = messageType === MessageTypeEnum.FILE
  const isMessageTypeSystemText =
    messageType === MessageTypeEnum.DELETED ||
    messageType === MessageTypeEnum.DISCARDED_FILE_PERMISSION
  const isMessageTypeSystem = messageType === MessageTypeEnum.SYSTEM
  const isMessageTypePinned = messageType === MessageTypeEnum.PINNED_MESSAGE
  const isMessageTypeRequest = messageType === MessageTypeEnum.CHAT_REQUEST
  const isMessageTypeMeeting = messageType === MessageTypeEnum.MEETING
  const isMessageTypeCall = messageType === MessageTypeEnum.CALL
  const isMessageTypeChatRequest = messageType === MessageTypeEnum.CHAT_REQUEST
  const isMessageTypeDeleted = messageType === MessageTypeEnum.DELETED

  const shouldShowUserName =
    groupStart &&
    (isCaseGroupChat ||
      isOrganizationChat ||
      isGroupChat ||
      isCommunityChannel ||
      isEventChannel ||
      isCaseView)

  const shouldShowAvatar =
    !isMobile &&
    !isMessageTypeSystem &&
    !isMessageTypeRequest &&
    !yourMessage &&
    !isCommunityNews &&
    !isEventNews &&
    !isDialogueChat &&
    !isCaseView &&
    !isMessageTypePinned

  const shouldShowFooter =
    !isMessageTypeSystem &&
    !isMessageTypePinned &&
    !isMessageTypeRequest &&
    !isMessageTypeMeeting &&
    !isMessageTypeSystemText &&
    !isMessageTypeCall

  const shouldShowCaseLabel =
    !isMessageTypeSystemText &&
    !editing &&
    !isOrganizationChat &&
    !isCommunityChannel &&
    !isCommunityNews &&
    !isEventChannel &&
    !isEventNews &&
    !isMessageTypeDeleted &&
    !isMessageTypeSystem &&
    !isMessageTypeChatRequest &&
    !isCaseGroupChat

  const isMeetingOrganizer =
    (isCaseView && yourMessage) || (isMessageTypeSystem && meeting?.organizer.id === accountId)

  const isImage =
    isMessageTypeFile &&
    !!file?.thumbnailUrl &&
    IMAGE_WHITELIST.includes(getExtensionWithoutDot(file?.extension ?? ''))

  const shouldShowTail =
    groupEnd &&
    !isMessageTypeSystem &&
    !isMessageTypeChatRequest &&
    !isMessageTypePinned &&
    !isImage

  const shouldShowMessageAuthor =
    !editing &&
    originalSender &&
    sendStatus !== ESendMessageStatus.SENDING &&
    !isCommunityNews &&
    !isEventNews

  const videoStatusSize = useMemo<TVideoReadinessStatusSize>(() => {
    if (isDesktop) {
      return 'lg'
    }

    if (isTablet) {
      return 'md'
    }

    return 'sm'
  }, [isDesktop, isTablet])

  const footerRight = useMemo(
    () => (
      <div className={styles.footerRight}>
        {file?.id && (
          <VideoReadinessStatus
            fileId={file.id}
            size={videoStatusSize}
            variant="inline"
            className={styles.videoStatus}
            color={isMessageOutgoing ? 'white' : 'default'}
          />
        )}

        {shouldShowCaseLabel && (
          <ChatMessageCaseLabel
            variant="icon"
            rejected={iconType === MessageIconTypeEnum.CASE_INVITE_REJECTION}
            case={caseData}
            yourMessage={yourMessage}
            chatId={chatId}
            redirectToCaseFromChatRequest={redirectToCaseFromChatRequest}
          />
        )}

        <ConnectionLabelIcon
          className={styles.connection}
          type={iconType}
          isMessageOutgoing={isMessageOutgoing}
        />
        {editing && (
          <ChatMessagePlaceholder
            icon={<Spinner contentCenter={true} width={24} height={24} />}
            label={t('chat.message.editingMessagePlaceholder')}
            isMessageOutgoing={isMessageOutgoing}
          />
        )}

        {!isImage && (
          <ChatMessageTime
            pin={pin}
            edited={edited}
            editing={editing}
            preview={isImage}
            createdAt={createdAt}
            recipientRead={recipientRead}
            sendStatus={sendStatus}
            yourMessage={yourMessage}
            chatRoomType={chatRoomType}
          />
        )}
      </div>
    ),
    [
      caseData,
      chatId,
      chatRoomType,
      createdAt,
      edited,
      editing,
      file,
      pin,
      iconType,
      isImage,
      isMessageOutgoing,
      recipientRead,
      redirectToCaseFromChatRequest,
      sendStatus,
      shouldShowCaseLabel,
      videoStatusSize,
      yourMessage,
      t
    ]
  )

  const getMessage = ({
    kebabRef,
    messageRef,
    longPressBind,
    lastName,
    firstName,
    type,
    displayUserName,
    userId,
    shouldShowKebab,
    contextMenuVisible,
    onContextMenuVisibleChange,
    onContextMenu
  }: TChatMessageWrapperReturnProps) => (
    <Skeleton
      loading={loading}
      active={true}
      avatar={false}
      paragraph={{ rows: 2 }}
      title={false}
      className={styles.skeleton}
    >
      {shouldShowAvatar && (
        <div
          className={cls({
            [styles.avatarContainer]: true,
            [styles.hide]: !yourMessage && !groupEnd && !showDate
          })}
        >
          <AvatarContainer
            userId={userId}
            displayUserName={displayUserName}
            lastName={lastName}
            firstName={firstName}
            type={type}
            size={EAvatarSize.SM}
          />
        </div>
      )}
      <div
        className={cls(
          {
            [styles.message]: true,
            [styles.messageOutgoing]: isMessageOutgoing || isMeetingOrganizer,
            [styles.messageFile]: isMessageTypeFile,
            [styles.messageImage]: isImage,
            [styles.messageCall]: isMessageTypeCall,
            [styles.messageSystem]: isMessageTypeSystem,
            [styles.messagePinned]: isMessageTypePinned,
            [styles.messageRequest]: isMessageTypeRequest,
            [styles.messageMeeting]: isMessageTypeMeeting && meeting,
            [styles.messageMeetingOwnPending]:
              meeting?.status === MeetingStatusEnum.PENDING && yourMessage,
            [styles.messageMeetingSystem]: isMessageTypeSystem && meeting,
            [styles.messageDeleted]: isMessageTypeSystemText,
            [styles.messageTail]: shouldShowTail,
            [styles.messageOutgoingTail]: shouldShowTail && isMessageOutgoing
          },
          'chat-message'
        )}
        {...longPressBind}
        ref={messageRef}
        onContextMenu={isDesktop ? onContextMenu : undefined}
      >
        {shouldShowMessageAuthor && (
          <ChatMessageAuthor sender={props.sender} originalSender={originalSender} />
        )}
        <div
          className={cls({
            [styles.messageContainer]: true,
            [styles.messageContainerSystem]: isMessageTypeSystem || isMessageTypeRequest
          })}
        >
          {!editing && (
            <>
              {getMapComponent(MESSAGES, messageType, {
                messageId: id,
                chatId,
                yourMessage,
                caseView,
                quoteMessage,
                sender: props.sender,
                showUserName: shouldShowUserName,
                footerRight: isMessageTypeCall ? footerRight : null,
                isMessageOutgoing,
                chatType: chatRoomType
              })}

              <ChatMessageKebabMenuContainer
                kebabRef={kebabRef}
                createdAt={createdAt}
                className={styles.contextMenu}
                isRead={recipientRead}
                yourMessage={yourMessage}
                chatId={chatId}
                messageId={id}
                sendStatus={sendStatus}
                messageType={messageType}
                preview={isImage}
                fileId={file?.id}
                fileName={file?.fileName}
                fileExtension={file?.extension}
                show={shouldShowKebab}
                receiverId={props.receiverId}
                readAll={props.readAll}
                slidesEntity={
                  file
                    ? [
                        {
                          id: file.id,
                          fileName: file.fileName,
                          extension: file.extension,
                          mimeType: file.mimeType,
                          thumbnailUrl: file.thumbnailUrl
                        }
                      ]
                    : []
                }
                isOriginalSender={isOriginalSender}
                visible={contextMenuVisible}
                meetingId={meeting?.id}
                meetingStatus={meeting?.status}
                onVisibleChange={onContextMenuVisibleChange}
                caseId={caseData?.id}
              />
            </>
          )}
        </div>
        {shouldShowFooter && (
          <div
            className={cls({
              [styles.footer]: true,
              [styles.footerWithEdit]: editing
            })}
          >
            {!editing && !isImage && (
              <ChatMessageReactionList
                className={styles.reactionsList}
                messageType={messageType}
                chatId={chatId}
                messageId={id}
                isMessageOutgoing={isMessageOutgoing}
              />
            )}

            {footerRight}
          </div>
        )}

        {!editing && isImage && (
          <div className={styles.image}>
            <ChatMessageReactionList
              className={styles.reactionsList}
              messageType={messageType}
              isImage={isImage}
              chatId={chatId}
              messageId={id}
              isMessageOutgoing={isMessageOutgoing}
            />

            <div className={styles.imageTimeWrapper}>
              {file?.id && (
                <VideoReadinessStatus
                  fileId={file.id}
                  size={videoStatusSize}
                  variant="inline"
                  className={styles.videoStatus}
                  color={isMessageOutgoing ? 'white' : 'default'}
                />
              )}
              <ChatMessageTime
                pin={pin}
                classes={{ time: styles.imageTime }}
                edited={edited}
                editing={editing}
                preview={isImage}
                createdAt={createdAt}
                recipientRead={recipientRead}
                sendStatus={sendStatus}
                yourMessage={yourMessage}
                chatRoomType={chatRoomType}
              />
            </div>
          </div>
        )}
      </div>
    </Skeleton>
  )

  return (
    <ChatMessageWrapper
      pin={pin}
      loading={loading}
      yourMessage={yourMessage}
      showDate={showDate}
      sendStatus={sendStatus}
      recipientRead={recipientRead}
      chatId={chatId}
      id={id}
      type={messageType}
      edited={edited}
      createdAt={createdAt}
      groupStart={groupStart}
      getMessage={getMessage}
      isOriginalSender={isOriginalSender}
      originalSender={originalSender}
      isMessageOutgoing={isMessageOutgoing}
      caseView={caseView}
      chatViewType={chatViewType}
      {...props}
    />
  )
}

export const ChatMessage = memo(ChatMessageView)
