import { useTranslation } from 'react-i18next'

import { IFilesEntity } from 'interfaces'
import { FileViewerProgress } from 'App/components'
import { useDownloadFile } from 'App/hooks'

import styles from './DocumentViewer.module.scss'

export type TDocumentViewerProps = Pick<IFilesEntity, 'id' | 'source'> & {
  originalEntityId?: string
}

export const DocumentViewer = ({ id, source, originalEntityId }: TDocumentViewerProps) => {
  const { error, progress, fileUrl } = useDownloadFile({
    source,
    id,
    originalEntityId,
    denyDownload: true
  })

  const { t } = useTranslation()

  return (
    <div className={styles.root}>
      {progress !== 100 ? (
        <FileViewerProgress
          percent={progress}
          label={!!error ? t('common.fileViewer.failure') : undefined}
          status={!!error ? 'exception' : undefined}
        />
      ) : (
        <iframe
          src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(fileUrl)}`}
          title="document"
          width="100%"
          height="100%"
          frameBorder="0"
        />
      )}
    </div>
  )
}
