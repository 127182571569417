import { Checkbox as OriginalCheckbox } from 'antd'
import { CheckboxProps as OriginalCheckboxProps } from 'antd/es/checkbox'
import cls from 'classnames'

import { TruncatedText } from '../TruncatedText'

import styles from './Checkbox.module.scss'

type TCheckboxClasses = 'root' | 'label'

export type TCheckboxProps = OriginalCheckboxProps &
  PropsWithClasses<
    TCheckboxClasses,
    {
      disabledLabel?: boolean
      label?: string | JSX.Element
      endAdornment?: JSX.Element
      capitalized?: boolean
      truncate?: boolean
      reverse?: boolean
    }
  >

export const Checkbox = ({
  label,
  disabled,
  disabledLabel = true,
  classes,
  endAdornment,
  capitalized,
  checked = false,
  truncate = true,
  reverse = false,
  ...rest
}: TCheckboxProps) => (
  <OriginalCheckbox
    {...rest}
    className={cls(styles.root, classes?.root, { [styles.reverse]: reverse })}
    disabled={disabled}
    checked={checked}
  >
    {label && (
      <div
        className={cls(styles.label, classes?.label, {
          [styles.disabled]: typeof label === 'string' && disabledLabel
        })}
      >
        {truncate ? (
          <TruncatedText
            text={label}
            classes={{
              root: styles.labelText,
              text: cls(styles.labelText, { [styles.capitalized]: capitalized })
            }}
          />
        ) : (
          <span className={cls({ [styles.capitalized]: capitalized })}>{label}</span>
        )}
      </div>
    )}
    {endAdornment}
  </OriginalCheckbox>
)
