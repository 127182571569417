import { io, Socket } from 'socket.io-client'
import { eventChannel } from 'redux-saga'
import Cookies from 'js-cookie'

import { unAuthorizedRedirect } from 'utils'
import config from 'config'

import { TWrapperForSocket, TSockets } from './webSocket.types'
import { ESocketNameSpaces, socketEventNamesMap } from './webSocket.namespaces'

export const sockets: Partial<TSockets> = {}

export const wrapperForSocketEmit = <P = unknown, D = unknown>(
  namespace: ESocketNameSpaces,
  eventName: string,
  payload: P
): Promise<void | D> =>
  new Promise<void | D>((resolve, reject) => {
    try {
      const socket = sockets[namespace]

      if (!socket) {
        throw new Error('socket not found')
      }

      if (!document.cookie.includes('session=')) {
        closeWebSocket()
        unAuthorizedRedirect()
        return reject()
      }

      socket.on('exception', (data: D) => {
        reject(data)
      })

      socket.emit(eventName, payload, (data: D) => {
        resolve(data)
      })
    } catch (error) {
      reject(error)
    }
  })

const wrapperForSocket: TWrapperForSocket = ({ socket: $socket, eventName, emitter }) => {
  $socket.on(eventName, (payload: unknown) =>
    emitter({
      eventName,
      payload
    })
  )
}

export const initWebSocket = (namespace: ESocketNameSpaces) => {
  const isNotDefault = namespace !== ESocketNameSpaces.DEFAULT
  const socketNamespaceUrl = isNotDefault ? `${config.apiUrlWS}/${namespace}` : config.apiUrlWS

  try {
    const sessionToken = Cookies.get('session')

    sockets[namespace] = io(socketNamespaceUrl, {
      transports: ['websocket', 'webtransport'],
      forceNew: false,
      secure: true,
      auth: {
        session: sessionToken
      }
    })
  } catch (error) {
    console.error(error)
  }

  const events = Object.values(socketEventNamesMap[namespace])
  const socket = sockets[namespace] as Socket

  return eventChannel((emitter) => {
    events.forEach((key) => {
      wrapperForSocket({
        socket,
        emitter,
        eventName: key
      })
    })

    return () => {
      socket.removeAllListeners()
    }
  })
}

export const closeWebSocket = () => {
  Object.values(ESocketNameSpaces).forEach((key) => {
    const socket = sockets[key]

    if (socket) {
      socket.disconnect()
    }
  })
}
