import {
  call,
  cancel,
  debounce,
  put,
  select,
  takeEvery,
  takeLatest,
  takeLeading,
  throttle
} from 'redux-saga/effects'
import { ChatStatusEnum, ChatTypeEnum, MessageTypeEnum } from '@medentee/enums'
import { generatePath } from 'react-router-dom'

import { toast } from 'App/components/ToastContainer'
import { API, api, APIData, APIResultsResponse } from 'services/api'
import { forwardTo } from 'utils'
import { CHAT_PATH, EChatTypeMap, toastDefaultOptions } from 'globalConstants'
import {
  chatRoomsNormalize,
  DELETE_MESSAGE_PERMANENTLY_SUCCESS,
  GET_CHAT_ROOMS_REQUEST,
  getChatRoomsError,
  getChatRoomsSuccess,
  getCommunityBasePathSelector,
  getOrganizationBasePathSelector,
  hideModalAction,
  isSameChatTab,
  LOAD_CHAT_INFO_REQUEST,
  loadChatInfoError,
  loadChatInfoSuccess,
  LOSE_ACCESS_TO_FILE,
  RECEIVE_DELETE_MESSAGE_PERMANENTLY_SUCCESS,
  removeContactReceive,
  REMOVED_CONTACT_RECEIVE,
  SEARCH_CHAT,
  TChatRoomsState,
  TLoadChatInfoRequest,
  TReceiveDeleteMessagePermanentlySuccess,
  updateChatRoomAction
} from 'store'
import { IChatInfoDTO, IChatRoomsDTO } from 'interfaces'
import { State } from 'redux/rootReducer'
import { ChatRoomQueryBuilder } from 'utils/QueryBuilder/ChatRoomQueryBuilder'
import {
  READ_MESSAGES_SUCCESS,
  RECEIVE_MESSAGE_EDITING_END_SUCCESS,
  receiveMessageEditingEndSuccess,
  TReadMessagesSuccess
} from 'store/chatMessages'
import history from 'utils/history'
import { TIds } from 'store/store.types'
import { shouldSkipAction } from 'services/skipWSActions'
import { EModalComponents } from 'App/containers/ModalRoot/ModalRoot.enums'
import {
  wrapperForSocketEmit,
  ESocketNameSpaces,
  ESocketEmitEventNamesChat
} from 'services/webSocket'
import { handleError } from 'api/utils'

import i18n from '../../i18n'

import {
  ISendTypingSuccess,
  TChatRoomLastMessage,
  TChatRoomsList,
  TGetChatRoomRequest,
  TGetChatRoomsRequest,
  TReceiveAccessToTextChannelPayload,
  TReceiveTypingAction,
  TReceiveTypingDoneAction,
  TSendTypingDoneRequest,
  TSendTypingRequest,
  TTypingInterlocutor
} from './chatRooms.types'
import {
  GET_CHAT_ROOM_REQUEST,
  getChatRoomError,
  getChatRoomsRequest,
  getChatRoomSuccess,
  RECEIVE_ADDED_USER_TO_CHAT,
  RECEIVE_CHAT_MUTED,
  RECEIVE_CHAT_UNMUTED,
  RECEIVE_DELEGATED_MANAGER_IN_TEXT_CHANNEL,
  RECEIVE_LOST_ACCESS_TO_TEXT_CHANNEL,
  RECEIVE_LOST_MANAGER_IN_TEXT_CHANNEL,
  RECEIVE_TYPING,
  RECEIVE_TYPING_DONE,
  receiveAddedUserToChat,
  receiveChatMuted,
  receiveLostAccessToTextChannel,
  receiveLostManagerInTextChannel,
  resetChatAction,
  SEND_TYPING_DONE_REQUEST,
  SEND_TYPING_REQUEST,
  sendTypingDoneError,
  sendTypingDoneSuccess,
  sendTypingError,
  sendTypingSuccess,
  SET_CHAT_FILTERS,
  setTypingDoneError,
  setTypingDoneSuccess,
  setTypingError,
  setTypingSuccess,
  startTyping
} from './chatRooms.actions'

const THROTTLE_TYPING_STARTED = 500
const DEBOUNCE_TYPING_ENDED = 2000

function* filtersChatSaga({ payload }: TGetChatRoomsRequest) {
  yield put(getChatRoomsRequest(payload))
}

function* getChatRoomsSaga({ payload }: TGetChatRoomsRequest) {
  try {
    const { caseId, organizationId, communityId, eventId, onSuccess } = payload || {}
    const {
      search,
      chatType,
      filters,
      ids: chatRoomIds,
      list: chatRoomList
    }: TChatRoomsState = yield select((state: State) => state.chat.chatRooms)

    const type = payload?.chatType || [chatType]

    const page = payload?.page
    const showBy = payload?.showBy

    const url: string = new ChatRoomQueryBuilder(API.CHAT_ROOMS)
      .caseId(caseId)
      .communityId(communityId)
      .eventId(eventId)
      .organizationId(organizationId)
      .searchQuery(search)
      .chatTypes(
        type.length === 1 && type.includes(ChatTypeEnum.DIALOGUE) && !caseId
          ? [ChatTypeEnum.DIALOGUE, ChatTypeEnum.GROUP]
          : type
      )
      .caseRoles(filters.caseRole)
      .page(page)
      .showBy(showBy)
      .build()

    const { data }: APIResultsResponse<IChatRoomsDTO[]> = yield call(api.get, url)

    const { ids, list } = chatRoomsNormalize(data.results)

    yield put(
      getChatRoomsSuccess({
        ids: !page ? [...ids] : [...chatRoomIds, ...ids],
        list: !page ? { ...list } : { ...chatRoomList, ...list },
        filters: { ...filters, page, showBy },
        total: data.total
      })
    )

    if (onSuccess) {
      yield call(onSuccess)
    }

    const selectedChat: IChatRoomsDTO | null = yield select(
      (state: State) => state.chat.chatRooms.selectedChat
    )

    if (
      selectedChat?.id &&
      list[selectedChat.id]?.status &&
      selectedChat.status !== list[selectedChat.id].status
    ) {
      yield put(
        updateChatRoomAction({
          selectedChat: {
            ...selectedChat,
            status: list[selectedChat.id].status
          }
        })
      )
    }
  } catch (e) {
    yield put(getChatRoomsError(e))

    handleError(e)
  }
}

function* getChatRoomSaga({ payload }: TGetChatRoomRequest) {
  try {
    const { chatId } = payload

    const { data }: APIData<IChatRoomsDTO> = yield call(api.get, API.CHAT_ROOM(chatId))

    yield put(getChatRoomSuccess(data))
  } catch (e) {
    yield handleError(e)
    yield put(getChatRoomError(e))
  }
}

function* receiveDeleteMessagePermanentlySaga({
  payload: { chatId }
}: TReceiveDeleteMessagePermanentlySuccess) {
  if (!chatId) {
    return
  }

  const { data }: APIData<IChatRoomsDTO> = yield call(api.get, API.CHAT_ROOM(chatId))

  const { list, selectedChat }: TChatRoomsState = yield select(
    (state: State) => state.chat.chatRooms
  )

  if (selectedChat?.id === chatId) {
    yield put(
      updateChatRoomAction({
        list: {
          ...list,
          [chatId]: {
            ...list[chatId],
            lastMessageDate: data.lastMessageDate,
            lastMessage: data.lastMessage
          }
        },
        selectedChat: {
          ...selectedChat,
          lastMessageDate: data.lastMessageDate,
          lastMessage: data.lastMessage
        }
      })
    )
  }
}

function* readMessageSuccessSaga({
  payload: { chatId, messageIds, readByYou }
}: TReadMessagesSuccess) {
  try {
    const { list, selectedChat }: TChatRoomsState = yield select(
      (state: State) => state.chat.chatRooms
    )

    let chatRoomLastMessage: TChatRoomLastMessage = yield select(
      (state: State) => (state.chat.chatRooms.list[chatId] ?? {})?.lastMessage
    )

    const messageId = messageIds[messageIds.length - 1]

    if (!chatRoomLastMessage) {
      const id: string = yield select((state: State) => state.chat.chatMessages.messages.ids[0])
      if (id) {
        chatRoomLastMessage = yield select(
          (state: State) => state.chat.chatMessages.messages.list[id]
        )
      }
    }

    if (!chatRoomLastMessage) {
      return
    }

    const {
      message,
      id,
      createdAt,
      quoteMessage,
      recipientRead,
      type,
      unixTs,
      yourMessage,
      originalSender,
      sender,
      mentionedAccounts,
      mentionedDepartments
    } = chatRoomLastMessage

    const lastMessage: TChatRoomLastMessage = {
      createdAt,
      id,
      message,
      quoteMessage,
      recipientRead,
      type,
      unixTs,
      yourMessage,
      originalSender,
      sender,
      mentionedAccounts,
      mentionedDepartments
    }

    if (
      lastMessage?.yourMessage &&
      messageIds.includes(lastMessage.id) &&
      (lastMessage?.type !== MessageTypeEnum.SYSTEM || readByYou)
    ) {
      lastMessage.recipientRead = true
    }

    if (readByYou || messageId === lastMessage?.id) {
      yield put(
        updateChatRoomAction({
          list: {
            ...list,
            [chatId]: {
              ...list[chatId],
              lastMessage: {
                ...list[chatId]?.lastMessage,
                ...lastMessage
              }
            }
          },
          selectedChat:
            selectedChat?.id === chatId
              ? {
                  ...selectedChat,
                  lastMessage: {
                    ...selectedChat?.lastMessage,
                    ...lastMessage
                  }
                }
              : selectedChat
        })
      )
    }
  } catch (e) {
    yield handleError(e)
  }
}

function* loadChatInfoSaga({ payload }: TLoadChatInfoRequest) {
  try {
    const { caseId } = payload

    const { data }: APIData<IChatInfoDTO> = yield call(api.get, API.CASE_INFO(caseId))

    yield put(loadChatInfoSuccess(data))
  } catch (e) {
    yield put(loadChatInfoError(e))
    yield handleError(e)
  }
}

function* removeContactConnectionSaga({
  payload: { producerId }
}: ReturnType<typeof removeContactReceive>) {
  try {
    const selectedChat: IChatRoomsDTO | null = yield select(
      (state: State) => state.chat.chatRooms.selectedChat
    )
    const list: TChatRoomsList = yield select((state: State) => state.chat.chatRooms.list)

    if (
      selectedChat?.interlocutorAccount?.id === producerId &&
      selectedChat.type !== ChatTypeEnum.ORGANIZATION &&
      selectedChat.type !== ChatTypeEnum.GROUP &&
      selectedChat.type !== ChatTypeEnum.COMMUNITY_NEWS &&
      selectedChat.type !== ChatTypeEnum.COMMUNITY_CHANNEL &&
      selectedChat.type !== ChatTypeEnum.EVENT_NEWS &&
      selectedChat.type !== ChatTypeEnum.EVENT_CHANNEL
    ) {
      yield history.replace(
        generatePath(CHAT_PATH, {
          chatId: selectedChat.id,
          chatType: EChatTypeMap[selectedChat.type]
        })
      )

      yield toast.info(i18n.t('common.toast.notConnectedWithUser'), toastDefaultOptions)

      yield put(
        updateChatRoomAction({
          list: {
            ...list,
            [selectedChat.id]: {
              ...list[selectedChat.id],
              isContact: false,
              status: ChatStatusEnum.INACTIVE
            }
          },
          selectedChat: {
            ...selectedChat,
            isContact: false,
            status: ChatStatusEnum.INACTIVE
          }
        })
      )
    }
  } catch (e) {
    yield handleError(e)
  }
}

function* messageEditingEndSaga({ payload }: ReturnType<typeof receiveMessageEditingEndSuccess>) {
  try {
    const selectedChat: IChatRoomsDTO | null = yield select(
      (state: State) => state.chat.chatRooms.selectedChat
    )
    const list: TChatRoomsList = yield select((state: State) => state.chat.chatRooms.list)
    const messageIds: TIds = yield select((state: State) => state.chat.chatMessages.messages.ids)

    if (payload.chatId === selectedChat?.id && payload.id === messageIds[messageIds.length - 1]) {
      yield put(
        updateChatRoomAction({
          list: {
            ...list,
            [payload.chatId]: {
              ...list[payload.chatId],
              lastMessage: payload
            }
          },
          selectedChat: {
            ...selectedChat,
            lastMessage: payload
          }
        })
      )
    }
  } catch (e) {
    yield handleError(e)
  }
}

function* getTextChannelsSaga(action: {
  type: string
  payload: TReceiveAccessToTextChannelPayload
}) {
  const { communityId, organizationId, eventId } = action.payload
  const currentOrganizationId: string | null = yield select(
    (state: State) => state.organizations.currentOrganizationId
  )

  const currentCommunityId: string | null = yield select(
    (state: State) => state.communities.currentCommunityId
  )

  const currentEventId: string | null = yield select((state: State) => state.events.currentEventId)

  if (shouldSkipAction(action)) {
    return
  }

  if (organizationId && organizationId === currentOrganizationId) {
    yield put(
      getChatRoomsRequest({
        organizationId,
        chatType: [ChatTypeEnum.ORGANIZATION]
      })
    )
  }

  if (communityId && communityId === currentCommunityId) {
    yield put(
      getChatRoomsRequest({
        communityId,
        chatType: [ChatTypeEnum.COMMUNITY_CHANNEL, ChatTypeEnum.COMMUNITY_NEWS]
      })
    )
  }

  if (eventId && eventId === currentEventId) {
    yield put(
      getChatRoomsRequest({
        eventId,
        chatType: [ChatTypeEnum.EVENT_CHANNEL, ChatTypeEnum.EVENT_NEWS]
      })
    )
  }
}

function* receiveChatMutedSaga({
  payload: { chatId, organizationId, communityId },
  type
}: ReturnType<typeof receiveChatMuted>) {
  try {
    const chatRoomsState: TChatRoomsState = yield select((state: State) => state.chat.chatRooms)
    const mutedUntil = type === RECEIVE_CHAT_MUTED ? new Date().toISOString() : null

    yield put(
      updateChatRoomAction({
        list: {
          ...chatRoomsState.list,
          [chatId]: {
            ...chatRoomsState.list[chatId],
            mutedUntil
          }
        },
        selectedChat:
          chatRoomsState.selectedChat?.id === chatId
            ? {
                ...chatRoomsState.selectedChat,
                mutedUntil
              }
            : chatRoomsState.selectedChat
      })
    )

    yield call(getTextChannelsSaga, { type, payload: { chatId, communityId, organizationId } })
  } catch (e) {
    yield handleError(e)
  }
}

function* receiveAddedUserToChatSaga({
  payload: { chatId }
}: ReturnType<typeof receiveAddedUserToChat>) {
  try {
    const chatRoomsState: TChatRoomsState = yield select((state: State) => state.chat.chatRooms)
    const dialogueOpened =
      chatRoomsState.chatType === ChatTypeEnum.DIALOGUE &&
      history.location.pathname.includes(`/chat/${EChatTypeMap.DIALOGUE}`)

    if (chatRoomsState.ids.includes(chatId) || !dialogueOpened) {
      return
    }

    const { data }: APIData<IChatRoomsDTO> = yield call(api.get, API.CHAT_ROOM(chatId))

    if (isSameChatTab(chatRoomsState.chatType, data.type)) {
      yield put(
        updateChatRoomAction({
          ids: [chatId, ...chatRoomsState.ids],
          list: {
            ...chatRoomsState.list,
            [chatId]: data
          }
        })
      )
    }
  } catch (e) {
    yield handleError(e)
  }
}

function* receiveLostAccessToTextChannelSaga({
  payload
}: ReturnType<typeof receiveLostAccessToTextChannel>) {
  const { chatId, type, communityId, organizationId, eventId } = payload

  const currentChatId: string | undefined = yield select(
    (state: State) => state.chat.chatRooms.selectedChat?.id
  )
  const currentChatType: ChatTypeEnum | undefined = yield select(
    (state: State) => state.chat.chatRooms.selectedChat?.type
  )

  const organizationPath: string = yield select(getOrganizationBasePathSelector(organizationId))
  const communityPath: string = yield select(getCommunityBasePathSelector(communityId))
  const eventPath = `/events/${eventId}`

  const currentOrganizationId: string | null = yield select(
    (state: State) => state.organizations.currentOrganizationId
  )

  const currentCommunityId: string | null = yield select(
    (state: State) => state.communities.currentCommunityId
  )

  const currentEventId: string | null = yield select((state: State) => state.events.currentEventId)

  if (currentChatId === chatId) {
    let path = `/chat/${currentChatType}`

    if (communityId) {
      path = communityPath
    }

    if (organizationId) {
      path = organizationPath
    }

    if (eventId) {
      path = eventPath
    }

    yield call(forwardTo, path)

    yield put(resetChatAction())

    const roomType = communityId || eventId || organizationId ? 'channel' : 'chat'

    if (!shouldSkipAction({ type: RECEIVE_LOST_ACCESS_TO_TEXT_CHANNEL, payload: { chatId } })) {
      yield toast.warn(
        i18n.t('common.toast.unavailableChat', { context: roomType }),
        toastDefaultOptions
      )
    }
  }

  if (type) {
    if (eventId && eventId === currentEventId) {
      yield put(
        getChatRoomsRequest({
          chatType: [ChatTypeEnum.EVENT_CHANNEL, ChatTypeEnum.EVENT_NEWS],
          eventId
        })
      )
      return
    }

    if (communityId && communityId === currentCommunityId) {
      yield put(
        getChatRoomsRequest({
          chatType: [ChatTypeEnum.COMMUNITY_CHANNEL, ChatTypeEnum.COMMUNITY_NEWS],
          communityId
        })
      )
      return
    }

    if (organizationId && organizationId === currentOrganizationId) {
      yield put(getChatRoomsRequest({ chatType: [ChatTypeEnum.ORGANIZATION], organizationId }))
      return
    }

    if (!organizationId && !eventId && !communityId) {
      yield put(
        getChatRoomsRequest({
          chatType:
            type === ChatTypeEnum.CASE_GROUP ? [type] : [ChatTypeEnum.DIALOGUE, ChatTypeEnum.GROUP]
        })
      )
    }
  }
}

function* receiveLostManagerInChannelSaga({
  payload: { organizationId, communityId, eventId }
}: ReturnType<typeof receiveLostManagerInTextChannel>) {
  const currentOrganizationId: string | null = yield select(
    (state: State) => state.organizations.currentOrganizationId
  )

  const currentCommunityId: string | null = yield select(
    (state: State) => state.communities.currentCommunityId
  )

  const currentEventId: string | null = yield select((state: State) => state.events.currentEventId)

  const currentModalType: EModalComponents | null = yield select(
    (state: State) => state.modal.modalType
  )

  if (
    (currentOrganizationId === organizationId &&
      currentModalType === EModalComponents.CREATE_TEXT_CHANNEL) ||
    (currentCommunityId === communityId &&
      currentModalType === EModalComponents.COMMUNITY_CREATE_CHANNEL) ||
    (currentEventId === eventId && currentModalType === EModalComponents.EVENT_CREATE_CHANNEL)
  ) {
    yield put(hideModalAction())
    yield toast.warn(
      i18n.t('serverError.DEFAULT_NOT_AVAILABLE_RESOURCE_MESSAGE', { ns: 'errors' }),
      toastDefaultOptions
    )
  }
}

function* sendTypingStartedSaga({ payload }: TSendTypingRequest) {
  try {
    const typing: boolean = yield select((state: State) => state.chat.chatRooms.typing)

    if (typing) {
      yield cancel()
    }

    yield put(startTyping())

    const typingStarted: ISendTypingSuccess = yield wrapperForSocketEmit(
      ESocketNameSpaces.CHAT,
      ESocketEmitEventNamesChat.TYPING_STARTED,
      payload
    )

    yield put(sendTypingSuccess(typingStarted))
  } catch (e) {
    yield put(sendTypingError(e))
    handleError(e)
  }
}

function* sendTypingDoneSaga({ payload }: TSendTypingDoneRequest) {
  try {
    const typingEnded: ISendTypingSuccess = yield wrapperForSocketEmit(
      ESocketNameSpaces.CHAT,
      ESocketEmitEventNamesChat.TYPING_ENDED,
      payload
    )

    yield put(sendTypingSuccess(typingEnded))
    yield put(sendTypingDoneSuccess(payload))
  } catch (e) {
    yield put(sendTypingDoneError(e))
    handleError(e)
  }
}

function* throttleTypingStart() {
  yield throttle(THROTTLE_TYPING_STARTED, SEND_TYPING_REQUEST, sendTypingStartedSaga)
}

function* debounceTypingDone() {
  yield debounce(DEBOUNCE_TYPING_ENDED, SEND_TYPING_DONE_REQUEST, sendTypingDoneSaga)
}

function* receiveTypingSaga({ payload: { userId, chatId, account } }: TReceiveTypingAction) {
  try {
    const accountId: string | undefined = yield select(
      (state: State) => state.global.accountData?.id
    )
    const typingInterlocutors: TTypingInterlocutor[] = yield select(
      (state: State) => state.chat.chatRooms.typingInterlocutors[chatId] ?? []
    )

    const alreadyTyping = typingInterlocutors.some(({ id }) => id === userId)

    if (userId !== accountId && !alreadyTyping) {
      const sortedTypingInterlocutors = [...typingInterlocutors, account].sort(
        (a, b) => a.displayUserName.length - b.displayUserName.length
      )

      yield put(setTypingSuccess({ chatId, typingInterlocutors: sortedTypingInterlocutors }))
    }
  } catch (e) {
    handleError(e)

    yield put(setTypingError(e))
  }
}

function* receiveTypingDoneSaga({ payload: { userId, chatId } }: TReceiveTypingDoneAction) {
  try {
    const accountId: string | undefined = yield select(
      (state: State) => state.global.accountData?.id
    )

    if (userId !== accountId) {
      const typingInterlocutors: TTypingInterlocutor[] = yield select(
        (state: State) => state.chat.chatRooms.typingInterlocutors[chatId] ?? []
      )

      const filteredTypingInterlocutors = typingInterlocutors.filter(({ id }) => id !== userId)

      yield put(setTypingDoneSuccess({ chatId, typingInterlocutors: filteredTypingInterlocutors }))
    }
  } catch (e) {
    handleError(e)
    yield put(setTypingDoneError(e))
  }
}

export function* chatRoomsSaga() {
  yield takeLatest([GET_CHAT_ROOMS_REQUEST], getChatRoomsSaga)
  yield takeEvery([GET_CHAT_ROOM_REQUEST], getChatRoomSaga)
  yield debounce(
    500,
    [
      RECEIVE_DELETE_MESSAGE_PERMANENTLY_SUCCESS,
      DELETE_MESSAGE_PERMANENTLY_SUCCESS,
      LOSE_ACCESS_TO_FILE
    ],
    receiveDeleteMessagePermanentlySaga
  )
  yield takeLatest(SEARCH_CHAT, filtersChatSaga)
  yield takeLatest(SET_CHAT_FILTERS, filtersChatSaga)
  yield takeLatest(READ_MESSAGES_SUCCESS, readMessageSuccessSaga)
  yield takeLatest(LOAD_CHAT_INFO_REQUEST, loadChatInfoSaga)
  yield takeEvery(REMOVED_CONTACT_RECEIVE, removeContactConnectionSaga)
  yield takeEvery(RECEIVE_MESSAGE_EDITING_END_SUCCESS, messageEditingEndSaga)
  yield takeEvery([RECEIVE_CHAT_MUTED, RECEIVE_CHAT_UNMUTED], receiveChatMutedSaga)
  yield takeEvery(RECEIVE_ADDED_USER_TO_CHAT, receiveAddedUserToChatSaga)

  yield takeLatest(RECEIVE_LOST_ACCESS_TO_TEXT_CHANNEL, receiveLostAccessToTextChannelSaga)
  yield takeLatest(
    [
      RECEIVE_LOST_MANAGER_IN_TEXT_CHANNEL,
      RECEIVE_DELEGATED_MANAGER_IN_TEXT_CHANNEL,
      RECEIVE_ADDED_USER_TO_CHAT
    ],
    getTextChannelsSaga
  )
  yield takeLatest(
    [RECEIVE_LOST_MANAGER_IN_TEXT_CHANNEL, RECEIVE_LOST_ACCESS_TO_TEXT_CHANNEL],
    receiveLostManagerInChannelSaga
  )
  yield takeLeading(SEND_TYPING_REQUEST, throttleTypingStart)
  yield takeLeading(SEND_TYPING_DONE_REQUEST, debounceTypingDone)
  yield takeEvery(RECEIVE_TYPING, receiveTypingSaga)
  yield takeEvery(RECEIVE_TYPING_DONE, receiveTypingDoneSaga)
}
