import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Select, TOption } from 'App/components'
import { TUserProfileNewProfessionProps } from 'App/containers'
import { ReactComponent as CrossIcon } from 'assets/icons/Cross.svg'

import styles from './UserProfileNewProfession.module.scss'

export const UserProfileNewProfession = ({
  availableProfessions,
  loadingProfessions,
  currentProfession,
  id,
  processing,
  isEditing = false,
  deleteNewProfession,
  addProfession,
  editProfession,
  accountType
}: TUserProfileNewProfessionProps) => {
  const [selectedProfession, selSelectedProfession] = useState<TOption | null>(
    isEditing ? currentProfession : null
  )

  const { t } = useTranslation()

  const onChange = (value: TOption | null) => {
    selSelectedProfession(value)
    if (value) {
      isEditing
        ? editProfession({
            id,
            professionId: value.value,
            active: true
          })
        : addProfession({
            professionId: value.value,
            tempProfessionId: id
          })
    }
  }

  const onDelete = () => {
    deleteNewProfession({ id })
  }

  const topLabel = t('userProfile.professions.selectTopLabel', { context: accountType })

  return (
    <div className={styles.root}>
      <div className={styles.specWrapper}>
        <div className={styles.inputWrapper}>
          <Select
            topLabel={!isEditing ? <span className={styles.inputLabel}>{topLabel}</span> : null}
            onChange={onChange}
            isMulti={false}
            value={selectedProfession}
            options={availableProfessions}
            isLoading={loadingProfessions}
            isDisabled={processing}
          />
        </div>
      </div>
      {!isEditing && (
        <button className={styles.removeBtn} onClick={onDelete}>
          {t('userProfile.professions.removeButton')}
          <CrossIcon className={styles.removeBtnIcon} />
        </button>
      )}
    </div>
  )
}
