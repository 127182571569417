import { call, put, select, takeLatest } from 'redux-saga/effects'
import { v4 as uuidV4 } from 'uuid'
import { ProfileLinkTypeNamesEnum, ErrorCodesEnum } from '@medentee/enums'

import { toast } from 'App/components/ToastContainer'
import { API, api, APIData } from 'services/api'
import { State } from 'redux/rootReducer'
import { EOnTheWebState } from 'enums'
import {
  formatProfiledProfessions,
  getCityNameWithTimezone,
  getTimezone,
  isMatchErrorCode,
  QueryBuilder
} from 'utils'
import { toastDefaultOptions } from 'globalConstants'
import history from 'utils/history'
import { TOption } from 'App/components/common/Fields/Select'
import {
  addEducationError,
  addEducationRequest,
  addEducationSuccess,
  addWorkingExperienceError,
  addWorkingExperienceRequest,
  addWorkingExperienceSuccess,
  ADD_EDUCATION_REQUEST,
  ADD_WORKING_EXPERIENCE_REQUEST,
  createProcessingSelector,
  getWorkExperienceRequest,
  updateEducationError,
  updateEducationRequest,
  updateEducationSuccess,
  updateWorkingExperienceError,
  updateWorkingExperienceRequest,
  updateWorkingExperienceSuccess,
  UPDATE_EDUCATION_REQUEST,
  UPDATE_WORKING_EXPERIENCE_REQUEST,
  UPDATE_RELATED_ACCOUNTS
} from 'store'
import {
  IEducationDTO,
  ILicenseDTO,
  IPublicationDTO,
  IRelatedAccountDTO,
  IWorkExperienceDTO
} from 'interfaces'
import { switchAccount } from 'api/profile'
import { handleError, handleWarning } from 'api/utils'

import { hideModalAction } from '../modal'
import i18n from '../../i18n'

import {
  accountsNormalize,
  educationNormalize,
  licensesNormalize,
  professionNormalize,
  publicationsNormalize,
  websiteNormalize,
  workExperienceNormalize
} from './userProfile.normalization'
import {
  TAddUserProfileProfessionRequest,
  TAddUserProfileSpecializationRequest,
  TAvailableProfessionsItemResponse,
  TChangeUserProfileAvatarRequest,
  TDdeleteUserProfileWebsiteRequest,
  TDeleteUserProfileNewProfession,
  TDeleteUserProfileProfessionRequest,
  TDeleteUserProfileSpecializationRequest,
  TEditBusinessAccount,
  TEditUserProfileBasicInformationRequest,
  TEditUserProfileOnTheWebRequest,
  TEditUserProfileProfessionRequest,
  TGetUserProfileByIdRequest,
  TGetUserProfileByIdResponse,
  TOnTheWeb,
  TProfessions,
  TProfessionsList,
  TSetUserProfileProfessionLoaders,
  TSwitchAccountEvent,
  TSwitchAccountRequest,
  TUserProfileBasicInformationInfo,
  TUserProfileBasicInformationInfoResponse
} from './userProfile.types'
import {
  ADD_USER_PROFILE_PROFESSION_REQUEST,
  ADD_USER_PROFILE_PROFESSIONS_NEW_ID,
  ADD_USER_PROFILE_SPECIALIZATION_REQUEST,
  ADD_USER_PROFILE_WEBSITE,
  addUserProfileProfessionError,
  addUserProfileSpecializationError,
  addUserProfileSpecializationSuccess,
  CHANGE_USER_PROFILE_AVATAR_REQUEST,
  changeUserProfileAvatarError,
  changeUserProfileAvatarSuccess,
  DELETE_USER_PROFILE_AVATAR_REQUEST,
  DELETE_USER_PROFILE_NEW_PROFESSION,
  DELETE_USER_PROFILE_PROFESSION_REQUEST,
  DELETE_USER_PROFILE_SPECIALIZATION_REQUEST,
  DELETE_USER_PROFILE_WEBSITE_REQUEST,
  deleteUserProfileAvatarError,
  deleteUserProfileAvatarSuccess,
  deleteUserProfileNewProfession,
  deleteUserProfileProfessionError,
  deleteUserProfileProfessionSuccess,
  deleteUserProfileSpecializationError,
  deleteUserProfileSpecializationSuccess,
  deleteUserProfileWebsiteError,
  deleteUserProfileWebsiteSuccess,
  EDIT_BUSINESS_ACCOUNT_REQUEST,
  EDIT_USER_PROFILE_BASIC_INFORMATION_REQUEST,
  EDIT_USER_PROFILE_ON_THE_WEB_REQUEST,
  EDIT_USER_PROFILE_PROFESSION_REQUEST,
  editBusinessAccountError,
  editBusinessAccountSuccess,
  editUserProfileBasicInformationError,
  editUserProfileBasicInformationSuccess,
  editUserProfileOnTheWebError,
  editUserProfileOnTheWebSuccess,
  editUserProfileProfessionError,
  editUserProfileProfessionSuccess,
  GET_AVAILABLE_PROFESSIONS_REQUEST,
  GET_RELATED_ACCOUNTS_REQUEST,
  GET_USER_PROFILE_BASIC_INFORMATION_REQUEST,
  GET_USER_PROFILE_BY_ID_REQUEST,
  GET_USER_PROFILE_ON_THE_WEB_REQUEST,
  GET_USER_PROFILE_PROFESSIONS_REQUEST,
  getAvailableProfessionsError,
  getAvailableProfessionsRequest,
  getAvailableProfessionsSuccess,
  getRelatedAccountsError,
  getRelatedAccountsSuccess,
  getUserProfileBasicInformationError,
  getUserProfileBasicInformationRequest,
  getUserProfileBasicInformationSuccess,
  getUserProfileByIdError,
  getUserProfileByIdSuccess,
  getUserProfileOnTheWebError,
  getUserProfileOnTheWebSuccess,
  getUserProfileProfessionsError,
  getUserProfileProfessionsRequest,
  getUserProfileProfessionsSuccess,
  SET_USER_PROFILE_PROFESSION_LOADERS,
  setUserProfileOnTheWebIds,
  setUserProfileOnTheWebList,
  setUserProfileProfessionLoaders,
  setUserProfileProfessionsIds,
  setUserProfileProfessionsList,
  setUserProfileProfessionsNewIds,
  setUserProfileWebsiteProcessing,
  SWITCH_ACCOUNT,
  SWITCH_ACCOUNT_EVENT,
  SWITCH_ACCOUNT_REQUEST,
  switchAccountError,
  getWorkExperienceSuccess,
  getWorkExperienceError,
  GET_WORK_EXPERIENCE_REQUEST,
  GET_EDUCATION_REQUEST,
  getEducationError,
  REMOVE_LICENSE_REQUEST,
  removeLicenseSuccess,
  removeLicenseRequest,
  removeLicenseError,
  getEducationSuccess,
  ADD_LICENSE_REQUEST,
  UPDATE_LICENSE_REQUEST,
  addLicenseRequest,
  updateLicenseRequest,
  addLicenseSuccess,
  addLicenseError,
  updateLicenseSuccess,
  updateLicenseError,
  removeWorkExperienceError,
  removeWorkExperienceSuccess,
  REMOVE_WORK_EXPERIENCE_REQUEST,
  removeWorkExperienceRequest,
  getEducationRequest,
  REMOVE_EDUCATION_REQUEST,
  removeEducationRequest,
  removeEducationSuccess,
  removeEducationError,
  GET_LICENSES_REQUEST,
  getLicensesSuccess,
  getLicensesError,
  getLicensesRequest,
  getPublicationsError,
  getPublicationsSuccess,
  GET_PUBLICATIONS_REQUEST,
  ADD_PUBLICATION_REQUEST,
  addPublicationRequest,
  addPublicationError,
  getPublicationsRequest,
  addPublicationSuccess,
  removePublicationRequest,
  REMOVE_PUBLICATION_REQUEST,
  removePublicationError,
  removePublicationSuccess,
  UPDATE_PUBLICATION_REQUEST,
  updatePublicationRequest,
  updatePublicationSuccess,
  updatePublicationError,
  addUserProfileProfessionSuccess
} from './userProfile.actions'

function* getMyProfileSaga() {
  try {
    const { data }: APIData<TUserProfileBasicInformationInfoResponse> = yield call(
      api.get,
      API.USER_PROFILE_BASIC_INFO
    )

    const formattedData: TUserProfileBasicInformationInfo = {
      ...data,
      city: {
        ...data.city,
        cityName: getCityNameWithTimezone(data.city.cityName, data.city.timezone)
      }
    }

    yield put(getUserProfileBasicInformationSuccess(formattedData))
  } catch (e) {
    yield handleError(e)
    yield put(getUserProfileBasicInformationError(e))
  }
}

function* changeUserProfileAvatarSaga({ payload }: TChangeUserProfileAvatarRequest) {
  try {
    const formData = new FormData()

    yield formData.append('file', payload.file)

    yield call(api.put, API.EDIT_USER_PROFILE_AVATAR, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })

    yield put(hideModalAction())
    yield put(getUserProfileBasicInformationRequest())
    yield put(changeUserProfileAvatarSuccess())
  } catch (e) {
    yield put(changeUserProfileAvatarError(e))
  }
}

function* deleteUserProfileAvatarSaga() {
  try {
    yield call(api.delete, API.EDIT_USER_PROFILE_AVATAR)

    yield put(hideModalAction())
    yield put(getUserProfileBasicInformationRequest())
    yield put(deleteUserProfileAvatarSuccess())
  } catch (e) {
    yield put(deleteUserProfileAvatarError(e))
  }
}

function* editUserProfileBasicInformationSaga({
  payload: { onSuccess, ...body }
}: TEditUserProfileBasicInformationRequest) {
  try {
    yield call(api.patch, API.USER_PROFILE_BASIC_INFO, body)

    yield put(getUserProfileBasicInformationRequest())
    yield put(editUserProfileBasicInformationSuccess())

    if (onSuccess) {
      yield call(onSuccess)
    }
  } catch (e) {
    yield handleError(e)
    yield put(editUserProfileBasicInformationError(e))
  }
}

function* getUserProfileOnTheWebSaga() {
  try {
    const { data } = yield call(api.get, API.USER_WEBSITES)

    const { websiteList, ids }: TOnTheWeb = websiteNormalize(data)

    yield put(setUserProfileOnTheWebIds(ids))
    yield put(setUserProfileOnTheWebList(websiteList))
    yield put(getUserProfileOnTheWebSuccess())
  } catch (e) {
    yield handleError(e)
    yield put(getUserProfileOnTheWebError(e))
  }
}

function* addUserProfileWebsiteSaga() {
  const currentState: TOnTheWeb = yield select((state: State) => ({
    ids: [...state.userProfile.onTheWeb.ids],
    websiteList: { ...state.userProfile.onTheWeb.websiteList }
  }))

  const uuid = uuidV4()
  currentState.ids.push(uuid)
  currentState.websiteList[uuid] = {
    editState: EOnTheWebState.EMPTY,
    url: '',
    processing: false,
    deletable: true,
    isNew: true,
    type: ProfileLinkTypeNamesEnum.WEBSITE
  }

  yield put(setUserProfileOnTheWebIds(currentState.ids))
  yield put(setUserProfileOnTheWebList(currentState.websiteList))
}

function* deleteUserProfileWebsiteSaga({ payload }: TDdeleteUserProfileWebsiteRequest) {
  const { id, isNew } = payload
  const ids: string[] = yield select((state: State) => [...state.userProfile.onTheWeb.ids])

  try {
    yield put(
      setUserProfileWebsiteProcessing({
        processing: true,
        id
      })
    )

    if (!isNew) {
      yield call(api.delete, API.USER_WEBSITES_EDIT(id))
    }

    ids.splice(ids.indexOf(payload.id), 1)

    yield put(setUserProfileOnTheWebIds(ids))

    yield put(deleteUserProfileWebsiteSuccess())
  } catch (e) {
    yield handleError(e)
    yield put(deleteUserProfileWebsiteError(e))
  } finally {
    yield put(
      setUserProfileWebsiteProcessing({
        processing: false,
        id
      })
    )
  }
}

function* editUserProfileWebsiteSaga({ payload }: TEditUserProfileOnTheWebRequest) {
  const { id, isNew, url } = payload

  try {
    yield put(
      setUserProfileWebsiteProcessing({
        processing: true,
        id
      })
    )

    const currentState: TOnTheWeb = yield select((state: State) => ({
      ids: [...state.userProfile.onTheWeb.ids],
      websiteList: { ...state.userProfile.onTheWeb.websiteList }
    }))

    if (!isNew) {
      yield call(api.put, API.USER_WEBSITES_EDIT(id), {
        url
      })

      yield put(
        setUserProfileWebsiteProcessing({
          processing: false,
          id
        })
      )

      return
    }

    const { data } = yield call(api.post, API.USER_WEBSITES, {
      url
    })

    currentState.ids[currentState.ids.indexOf(id)] = data.id

    currentState.websiteList[data.id] = {
      ...currentState.websiteList[id],
      editState: url ? EOnTheWebState.WITH_DATA : EOnTheWebState.EMPTY,
      url: url ? url : '',
      isNew: false,
      processing: false
    }

    delete currentState.websiteList[id]

    yield put(
      editUserProfileOnTheWebSuccess({
        ids: currentState.ids,
        websiteList: currentState.websiteList
      })
    )
  } catch (e) {
    yield handleError(e)
    yield put(editUserProfileOnTheWebError(e))
  }
}

function* getUserProfileByIdSaga({ payload }: TGetUserProfileByIdRequest) {
  const { id, token } = payload

  try {
    const userId: string | undefined = yield select((state: State) => state.global.accountData?.id)

    const url = (() => {
      if (userId === id) {
        return API.USER_PROFILE
      }

      return new QueryBuilder(API.USER_PROFILE_CONTACT_VIEW(id))
        .custom('invitationToken', token)
        .build()
    })()

    const { data }: APIData<TGetUserProfileByIdResponse> = yield call(api.get, url)

    const {
      type,
      firstName,
      lastName,
      headline,
      about,
      country,
      city,
      professions,
      profileLinks,
      chatId,
      companyInfo,
      displayUserName,
      workplaces,
      publications,
      licenses,
      educations,
      isContact,
      isCoworker,
      contact
    } = data
    let facebook = ''
    let linkedIn = ''
    let twitter = ''
    const websites: string[] = []

    profileLinks &&
      profileLinks.forEach((item) => {
        switch (item.type) {
          case ProfileLinkTypeNamesEnum.FACEBOOK:
            facebook = item.url
            break
          case ProfileLinkTypeNamesEnum.LINKEDIN:
            linkedIn = item.url
            break
          case ProfileLinkTypeNamesEnum.TWITTER:
            twitter = item.url
            break
          case ProfileLinkTypeNamesEnum.WEBSITE:
            websites.push(item.url)
            break
          default:
            break
        }
      })

    const formattedProfessions = formatProfiledProfessions(professions)

    yield put(
      getUserProfileByIdSuccess({
        country: country.countryName,
        city: city.cityName,
        timezone: getTimezone(city.timezone),
        professions: formattedProfessions,
        id,
        type,
        displayUserName,
        firstName,
        lastName,
        headline,
        about,
        facebook,
        linkedIn,
        twitter,
        websites,
        chatId,
        companyInfo,
        workExperience: workExperienceNormalize(workplaces),
        licenses: licensesNormalize(licenses),
        education: educationNormalize(educations),
        publications: publicationsNormalize(publications),
        isContact,
        isCoworker,
        contact
      })
    )
  } catch (e) {
    yield put(getUserProfileByIdError(e))
    history.push('/contacts')

    yield isMatchErrorCode(e, [
      ErrorCodesEnum.NO_CONTACT_EXIST,
      ErrorCodesEnum.CANNOT_VIEW_USER_PROFILE
    ])
      ? handleWarning(e)
      : handleError(e)
  }
}

function* getUserProfileProfessionsSaga() {
  try {
    const { data } = yield call(api.get, API.USER_PROFESSIONS)

    const { list, ids } = professionNormalize(data)

    yield put(setUserProfileProfessionsIds(ids))
    yield put(setUserProfileProfessionsList(list))
    yield put(getUserProfileProfessionsSuccess())
  } catch (e) {
    yield handleError(e)
    yield put(getUserProfileProfessionsError(e))
  }
}

function* addUserProfileProfessionsNewIdSaga() {
  const newIds: string[] = yield select((state: State) => [...state.userProfile.professions.newIds])

  newIds.push(uuidV4())

  yield put(setUserProfileProfessionsNewIds(newIds))
}

function* getAvailableProfessionsSaga() {
  try {
    const { data }: APIData<TAvailableProfessionsItemResponse[]> = yield call(
      api.get,
      API.USER_AVAILABLE_PROFESSIONS
    )

    const formattedData: TOption[] = data.map((item: TAvailableProfessionsItemResponse) => ({
      label: item.name,
      value: item.id
    }))

    yield put(getAvailableProfessionsSuccess(formattedData))
  } catch (e) {
    yield handleError(e)
    yield put(getAvailableProfessionsError(e))
  }
}

function* deleteUserProfileNewProfessionSaga({ payload }: TDeleteUserProfileNewProfession) {
  const ids: string[] = yield select((state: State) => [...state.userProfile.professions.newIds])

  ids.splice(ids.indexOf(payload.id), 1)

  yield put(setUserProfileProfessionsNewIds(ids))
}

function* addUserProfileProfessionSaga({ payload }: TAddUserProfileProfessionRequest) {
  try {
    const { professionId, tempProfessionId } = payload
    const currentState: TProfessions = yield select((state: State) => ({
      ...state.userProfile.professions,
      ids: [...state.userProfile.professions.ids],
      professionsList: { ...state.userProfile.professions.professionsList }
    }))

    yield call(api.post, API.USER_PROFESSIONS, {
      professionId
    })

    yield put(getUserProfileProfessionsRequest())

    yield put(deleteUserProfileNewProfession({ id: tempProfessionId }))

    yield put(setUserProfileProfessionsIds(currentState.ids))
    yield put(setUserProfileProfessionsList(currentState.professionsList))

    yield put(getAvailableProfessionsRequest())
    yield put(addUserProfileProfessionSuccess())
  } catch (e) {
    yield handleError(e)
    yield put(addUserProfileProfessionError(e))
  }
}

function* deleteUserProfileProfessionSaga({ payload }: TDeleteUserProfileProfessionRequest) {
  const { id } = payload

  try {
    yield call(api.delete, API.USER_PROFILE_EDIT_PROFESSION(id))

    yield put(getUserProfileProfessionsRequest())
    yield put(getAvailableProfessionsRequest())

    yield put(deleteUserProfileProfessionSuccess())
    yield put(hideModalAction())
  } catch (e) {
    yield handleError(e)
    yield put(deleteUserProfileProfessionError(e))
  }
}

function* editUserProfileProfessionSaga({ payload }: TEditUserProfileProfessionRequest) {
  const { id, active, professionId } = payload

  try {
    const list: TProfessionsList = yield select((state: State) => ({
      ...state.userProfile.professions.professionsList
    }))

    yield put(
      setUserProfileProfessionLoaders({
        professionId: id,
        professionLoading: true
      })
    )

    yield call(api.put, API.USER_PROFILE_EDIT_PROFESSION(id), {
      isActive: active,
      professionId
    })

    list[id].isActive = active
    yield put(getAvailableProfessionsRequest())
    yield put(setUserProfileProfessionsList(list))
    yield put(editUserProfileProfessionSuccess())
  } catch (e) {
    yield handleError(e)
    yield put(editUserProfileProfessionError(e))
  } finally {
    yield put(
      setUserProfileProfessionLoaders({
        professionId: id
      })
    )
  }
}

function* addUserProfileSpecializationSaga({ payload }: TAddUserProfileSpecializationRequest) {
  const { specialization, professionId } = payload

  try {
    const professionsList: TProfessionsList = yield select((state: State) => ({
      ...state.userProfile.professions.professionsList
    }))

    yield put(
      setUserProfileProfessionLoaders({
        specializationsLoading: true,
        professionId
      })
    )

    const { data } = yield call(api.post, API.USER_PROFESSION_SPECIALIZATIONS(professionId), {
      name: specialization
    })

    professionsList[professionId].specializations = data

    yield put(
      setUserProfileProfessionsList({
        ...professionsList
      })
    )

    yield put(addUserProfileSpecializationSuccess())
  } catch (e) {
    yield handleError(e)
    yield put(addUserProfileSpecializationError(e))
  } finally {
    yield put(
      setUserProfileProfessionLoaders({
        professionId
      })
    )
  }
}

function* deleteUserProfileSpecializationSaga({
  payload
}: TDeleteUserProfileSpecializationRequest) {
  const { professionId, specializationId } = payload

  try {
    const professionsList: TProfessionsList = yield select((state: State) => ({
      ...state.userProfile.professions.professionsList
    }))

    yield put(
      setUserProfileProfessionLoaders({
        specializationsLoading: true,
        professionId
      })
    )

    const { data } = yield call(
      api.delete,
      API.USER_PROFESSION_SPECIALIZATIONS_DELETE(professionId, specializationId)
    )

    professionsList[professionId].specializations = data

    yield put(setUserProfileProfessionsList(professionsList))
    yield put(deleteUserProfileSpecializationSuccess())
  } catch (e) {
    yield handleError(e)
    yield put(deleteUserProfileSpecializationError(e))
  } finally {
    yield put(
      setUserProfileProfessionLoaders({
        professionId,
        specializationsLoading: false
      })
    )
  }
}

function* setUserProfileProfessionLoadersSaga({ payload }: TSetUserProfileProfessionLoaders) {
  const { professionLoading, specializationsLoading, professionId } = payload
  const professionsList: TProfessionsList = yield select((state: State) => ({
    ...state.userProfile.professions.professionsList
  }))

  professionsList[professionId] = {
    ...professionsList[professionId],
    professionLoading: professionLoading || false,
    specializationsLoading: specializationsLoading || false
  }

  yield put(setUserProfileProfessionsList(professionsList))
}

function* getRelatedAccountsSaga() {
  try {
    const { data }: APIData<IRelatedAccountDTO[]> = yield call(api.get, API.USER_RELATED_ACCOUNTS)

    const payload = accountsNormalize(data)

    yield put(getRelatedAccountsSuccess(payload))
  } catch (e) {
    yield handleError(e)
    yield put(getRelatedAccountsError(e))
  }
}

function* switchAccountSaga({ payload: { accountId } }: TSwitchAccountRequest) {
  try {
    yield call(switchAccount, { accountId })

    yield (window.location.pathname = '/')
  } catch (e) {
    yield isMatchErrorCode(e, ErrorCodesEnum.BUSINESS_ADMIN_ACCOUNT_SUSPENDED)
      ? toast.info(i18n.t('common.toast.unavailableBusinessAccount'), toastDefaultOptions)
      : handleError(e)

    yield put(switchAccountError(e))
  }
}

function* switchAccountEventSaga({ payload: { userId } }: TSwitchAccountEvent) {
  try {
    const processingSelector = createProcessingSelector([SWITCH_ACCOUNT])

    const accountId: string = yield select((state: State) => state.global.accountData?.id)
    const processing: boolean = yield select(processingSelector)

    if (!processing && accountId !== userId) {
      yield (window.location.pathname = '/')
    }
  } catch (e) {
    yield handleError(e)
    yield put(switchAccountError(e))
  }
}

function* editBusinessAccountSaga({ payload: { onSuccess, ...body } }: TEditBusinessAccount) {
  try {
    yield call(api.patch, API.EDIT_BUSINESS_ACCOUNT, body)

    yield put(getUserProfileBasicInformationRequest())
    yield put(editBusinessAccountSuccess())

    if (onSuccess) {
      yield call(onSuccess)
    }
  } catch (e) {
    yield put(editBusinessAccountError(e))
  }
}

function* addWorkingExperienceSaga({ payload }: ReturnType<typeof addWorkingExperienceRequest>) {
  try {
    yield call(api.post, API.WORK_EXPERIENCE, payload)

    yield put(hideModalAction())

    yield put(addWorkingExperienceSuccess())
    yield put(getWorkExperienceRequest())
  } catch (e) {
    yield handleError(e)
    yield put(addWorkingExperienceError(e))
  }
}

function* updateWorkingExperienceSaga({
  payload: { id, processingId, ...data }
}: ReturnType<typeof updateWorkingExperienceRequest>) {
  try {
    yield call(api.patch, API.WORK_EXPERIENCE_RECORD(id), data)

    yield put(hideModalAction())

    yield put(updateWorkingExperienceSuccess({ processingId }))
    yield put(getWorkExperienceRequest())
  } catch (e) {
    yield handleError(e)
    yield put(updateWorkingExperienceError({ processingId, ...e }))
  }
}

function* addLicenseSaga({ payload }: ReturnType<typeof addLicenseRequest>) {
  try {
    yield call(api.post, API.LICENSES, payload)

    yield put(hideModalAction())

    yield put(addLicenseSuccess())
    yield put(getLicensesRequest())
  } catch (e) {
    yield handleError(e)
    yield put(addLicenseError(e))
  }
}

function* updateLicenseSaga({
  payload: { id, processingId, ...data }
}: ReturnType<typeof updateLicenseRequest>) {
  try {
    yield call(api.patch, API.LICENSES_RECORD(id), data)

    yield put(hideModalAction())

    yield put(updateLicenseSuccess({ processingId }))
    yield put(getLicensesRequest())
  } catch (e) {
    yield handleError(e)
    yield put(updateLicenseError({ processingId, ...e }))
  }
}

function* addEducationSaga({ payload }: ReturnType<typeof addEducationRequest>) {
  try {
    yield call(api.post, API.EDUCATION, payload)

    yield put(hideModalAction())

    yield put(addEducationSuccess())
    yield put(getEducationRequest())
  } catch (e) {
    yield handleError(e)
    yield put(addEducationError(e))
  }
}

function* updateEducationSaga({
  payload: { id, processingId, ...data }
}: ReturnType<typeof updateEducationRequest>) {
  try {
    yield call(api.patch, API.EDUCATION_RECORD(id), data)

    yield put(hideModalAction())

    yield put(updateEducationSuccess({ processingId }))
    yield put(getEducationRequest())
  } catch (e) {
    yield handleError(e)
    yield put(updateEducationError({ processingId, ...e }))
  }
}

function* getWorkExperienceSaga() {
  try {
    const { data }: APIData<IWorkExperienceDTO[]> = yield call(api.get, API.WORK_EXPERIENCE)

    yield put(getWorkExperienceSuccess(workExperienceNormalize(data)))
  } catch (e) {
    yield put(getWorkExperienceError(e))
    yield handleError(e)
  }
}

function* removeWorkExperienceSaga({
  payload: { id }
}: ReturnType<typeof removeWorkExperienceRequest>) {
  try {
    yield call(api.delete, API.WORK_EXPERIENCE_RECORD(id))

    yield put(removeWorkExperienceSuccess())
  } catch (e) {
    yield put(removeWorkExperienceError(e))
    yield handleError(e)
  } finally {
    yield put(getWorkExperienceRequest())
    yield put(hideModalAction())
  }
}

function* getEducationSaga() {
  try {
    const { data }: APIData<IEducationDTO[]> = yield call(api.get, API.EDUCATION)

    yield put(getEducationSuccess(educationNormalize(data)))
  } catch (e) {
    yield put(getEducationError(e))
    yield handleError(e)
  }
}

function* removeLicenseSaga({ payload: { id } }: ReturnType<typeof removeLicenseRequest>) {
  try {
    yield call(api.delete, API.LICENSES_RECORD(id))

    yield put(removeLicenseSuccess())
  } catch (e) {
    yield put(removeLicenseError(e))
    yield handleError(e)
  } finally {
    yield put(getLicensesRequest())
    yield put(hideModalAction())
  }
}

function* removeEducationSaga({ payload: { id } }: ReturnType<typeof removeEducationRequest>) {
  try {
    yield call(api.delete, API.EDUCATION_RECORD(id))

    yield put(removeEducationSuccess())
  } catch (e) {
    yield put(removeEducationError(e))
    yield handleError(e)
  } finally {
    yield put(getEducationRequest())
    yield put(hideModalAction())
  }
}

function* getLicensesSaga() {
  try {
    const { data }: APIData<ILicenseDTO[]> = yield call(api.get, API.LICENSES)

    yield put(getLicensesSuccess(licensesNormalize(data)))
  } catch (e) {
    yield put(getLicensesError(e))
    yield handleError(e)
  }
}

function* getPublicationsSaga() {
  try {
    const { data }: APIData<IPublicationDTO[]> = yield call(api.get, API.PUBLICATIONS)

    yield put(getPublicationsSuccess(publicationsNormalize(data)))
  } catch (e) {
    yield put(getPublicationsError(e))
    yield handleError(e)
  }
}

function* addPublicationSaga({ payload }: ReturnType<typeof addPublicationRequest>) {
  try {
    yield call(api.post, API.PUBLICATIONS, payload)

    yield put(hideModalAction())

    yield put(addPublicationSuccess())
    yield put(getPublicationsRequest())
  } catch (e) {
    yield handleError(e)
    yield put(addPublicationError(e))
  }
}

function* removePublicationSaga({ payload: { id } }: ReturnType<typeof removePublicationRequest>) {
  try {
    yield call(api.delete, API.PUBLICATIONS_RECORD(id))

    yield put(removePublicationSuccess())
  } catch (e) {
    yield put(removePublicationError(e))
    yield handleError(e)
  } finally {
    yield put(getPublicationsRequest())
    yield put(hideModalAction())
  }
}

function* updatePublicationSaga({
  payload: { id, processingId, ...data }
}: ReturnType<typeof updatePublicationRequest>) {
  try {
    yield call(api.patch, API.PUBLICATIONS_RECORD(id), data)

    yield put(hideModalAction())

    yield put(updatePublicationSuccess({ processingId }))
    yield put(getPublicationsRequest())
  } catch (e) {
    yield handleError(e)
    yield put(updatePublicationError({ processingId, ...e }))
  }
}

export function* userProfileSaga() {
  yield takeLatest(GET_USER_PROFILE_BASIC_INFORMATION_REQUEST, getMyProfileSaga)
  yield takeLatest(CHANGE_USER_PROFILE_AVATAR_REQUEST, changeUserProfileAvatarSaga)
  yield takeLatest(DELETE_USER_PROFILE_AVATAR_REQUEST, deleteUserProfileAvatarSaga)
  yield takeLatest(EDIT_USER_PROFILE_BASIC_INFORMATION_REQUEST, editUserProfileBasicInformationSaga)
  yield takeLatest(GET_USER_PROFILE_ON_THE_WEB_REQUEST, getUserProfileOnTheWebSaga)
  yield takeLatest(ADD_USER_PROFILE_WEBSITE, addUserProfileWebsiteSaga)
  yield takeLatest(GET_USER_PROFILE_BY_ID_REQUEST, getUserProfileByIdSaga)
  yield takeLatest(GET_USER_PROFILE_PROFESSIONS_REQUEST, getUserProfileProfessionsSaga)
  yield takeLatest(ADD_USER_PROFILE_PROFESSIONS_NEW_ID, addUserProfileProfessionsNewIdSaga)
  yield takeLatest(GET_AVAILABLE_PROFESSIONS_REQUEST, getAvailableProfessionsSaga)
  yield takeLatest(DELETE_USER_PROFILE_NEW_PROFESSION, deleteUserProfileNewProfessionSaga)
  yield takeLatest(ADD_USER_PROFILE_PROFESSION_REQUEST, addUserProfileProfessionSaga)
  yield takeLatest(DELETE_USER_PROFILE_PROFESSION_REQUEST, deleteUserProfileProfessionSaga)
  yield takeLatest(EDIT_USER_PROFILE_PROFESSION_REQUEST, editUserProfileProfessionSaga)
  yield takeLatest(ADD_USER_PROFILE_SPECIALIZATION_REQUEST, addUserProfileSpecializationSaga)
  yield takeLatest(DELETE_USER_PROFILE_SPECIALIZATION_REQUEST, deleteUserProfileSpecializationSaga)
  yield takeLatest(SET_USER_PROFILE_PROFESSION_LOADERS, setUserProfileProfessionLoadersSaga)
  yield takeLatest(DELETE_USER_PROFILE_WEBSITE_REQUEST, deleteUserProfileWebsiteSaga)
  yield takeLatest(EDIT_USER_PROFILE_ON_THE_WEB_REQUEST, editUserProfileWebsiteSaga)
  yield takeLatest([GET_RELATED_ACCOUNTS_REQUEST, UPDATE_RELATED_ACCOUNTS], getRelatedAccountsSaga)
  yield takeLatest(SWITCH_ACCOUNT_REQUEST, switchAccountSaga)
  yield takeLatest(SWITCH_ACCOUNT_EVENT, switchAccountEventSaga)
  yield takeLatest(EDIT_BUSINESS_ACCOUNT_REQUEST, editBusinessAccountSaga)
  yield takeLatest(ADD_WORKING_EXPERIENCE_REQUEST, addWorkingExperienceSaga)
  yield takeLatest(UPDATE_WORKING_EXPERIENCE_REQUEST, updateWorkingExperienceSaga)
  yield takeLatest(ADD_LICENSE_REQUEST, addLicenseSaga)
  yield takeLatest(UPDATE_LICENSE_REQUEST, updateLicenseSaga)
  yield takeLatest(ADD_EDUCATION_REQUEST, addEducationSaga)
  yield takeLatest(UPDATE_EDUCATION_REQUEST, updateEducationSaga)
  yield takeLatest(GET_WORK_EXPERIENCE_REQUEST, getWorkExperienceSaga)
  yield takeLatest(GET_EDUCATION_REQUEST, getEducationSaga)
  yield takeLatest(REMOVE_LICENSE_REQUEST, removeLicenseSaga)
  yield takeLatest(REMOVE_WORK_EXPERIENCE_REQUEST, removeWorkExperienceSaga)
  yield takeLatest(REMOVE_EDUCATION_REQUEST, removeEducationSaga)
  yield takeLatest(GET_LICENSES_REQUEST, getLicensesSaga)
  yield takeLatest(GET_PUBLICATIONS_REQUEST, getPublicationsSaga)
  yield takeLatest(ADD_PUBLICATION_REQUEST, addPublicationSaga)
  yield takeLatest(REMOVE_PUBLICATION_REQUEST, removePublicationSaga)
  yield takeLatest(UPDATE_PUBLICATION_REQUEST, updatePublicationSaga)
}
