import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { AccountTypeNames } from '@medentee/enums'

import { State } from 'redux/rootReducer'
import { createLoadingSelector, createProcessingSelector } from 'store/loading'
import {
  addUserProfileProfessionRequest,
  ADD_USER_PROFILE_PROFESSION,
  deleteUserProfileNewProfession,
  GET_AVAILABLE_PROFESSIONS,
  editUserProfileProfessionRequest
} from 'store/userProfile'
import { UserProfileNewProfession } from 'App/components/UserProfile/UserProfileNewProfession'

type UserProfileNewProfessionProps = {
  id: string
  isEditing?: boolean
}

export type TUserProfileNewProfessionProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const loadingProfessionsSelector = createLoadingSelector([GET_AVAILABLE_PROFESSIONS])
const processingSelector = createProcessingSelector([ADD_USER_PROFILE_PROFESSION])

const mapStateToProps = (state: State, ownProps: UserProfileNewProfessionProps) => ({
  accountType: state.global.accountData?.type.name ?? AccountTypeNames.PROFESSIONAL,
  availableProfessions: state.userProfile.professions.availableProfessions,
  currentProfession: ownProps.isEditing
    ? {
        value: state.userProfile.professions.professionsList[ownProps.id].professionId,
        label: state.userProfile.professions.professionsList[ownProps.id].name
      }
    : null,
  loadingProfessions: loadingProfessionsSelector(state),
  processing: processingSelector(state),
  id: ownProps.id,
  isEditing: ownProps.isEditing
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      deleteNewProfession: deleteUserProfileNewProfession,
      addProfession: addUserProfileProfessionRequest,
      editProfession: editUserProfileProfessionRequest
    },
    dispatch
  )

export const UserProfileNewProfessionContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserProfileNewProfession)
