import { useCallback } from 'react'
import { useHistory } from 'react-router'
import { EventTypeEnum } from '@medentee/enums'
import { toast } from 'react-toastify'
import isArray from 'lodash/isArray'
import { useTranslation } from 'react-i18next'

import { useMutation } from 'services/query'
import { createEvent, TCreateEventPayload } from 'api/events'
import { TEventInfoFormValues } from 'App/components/Events/EventInfoForm'
import { toastDefaultOptions } from 'globalConstants'

type TUseCreateEventRequestProps = {
  onSuccess?: () => void
} & Pick<TCreateEventPayload, 'representativeId' | 'representativeType'>

export const useCreateEventRequest = (props?: TUseCreateEventRequestProps) => {
  const { representativeId, representativeType, onSuccess } = props ?? {}

  const { push } = useHistory()

  const { t } = useTranslation()

  const { isLoading, mutate } = useMutation({
    mutationFn: createEvent,
    onSuccess: ({ id }) => {
      onSuccess && onSuccess()
      push(`/events/${id}/highlights/info`)
      toast.success(t('events.createEventSuccessToast'), toastDefaultOptions)
    }
  })

  const handleSubmit = useCallback(
    ({ location, city, dates, title }: TEventInfoFormValues) => {
      const isRangeDates = isArray(dates)
      const startOn = isRangeDates ? dates[0] : dates
      const endOn = isRangeDates ? dates[1] : dates

      if (!startOn || !endOn) {
        return
      }

      const baseBody = {
        title,
        startOn,
        endOn,
        cityId: city ? city.id.toString() : undefined,
        location,
        type: EventTypeEnum.OFFLINE
      }

      if (representativeId && representativeType) {
        return mutate({
          ...baseBody,
          representativeId,
          representativeType
        })
      }

      return mutate(baseBody)
    },
    [mutate, representativeId, representativeType]
  )

  return { isLoading, handleSubmit }
}
