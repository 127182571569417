import { useCallback, useEffect } from 'react'
import pdfobject from 'pdfobject'
import { Trans, useTranslation } from 'react-i18next'

import { IFilesEntity } from 'interfaces'
import { Button, EmptyList, FileViewerProgress } from 'App/components'
import { useDownloadFile } from 'App/hooks'
import { ReactComponent as ToastWarningIcon } from 'assets/icons/ToastWarning.svg'
import { downloadFileRequest, useAppDispatch } from 'store'

import styles from './PDFViewer.module.scss'

export type TPDFViewerProps = Pick<IFilesEntity, 'id' | 'source'> & {
  originalEntityId?: string
}

const ROOT_ID = 'pdf-viewer'

export const PDFViewer = ({ id, source, originalEntityId }: TPDFViewerProps) => {
  const dispatch = useAppDispatch()

  const { error, progress, src } = useDownloadFile({
    source,
    id,
    originalEntityId,
    type: 'application/pdf'
  })

  const { t } = useTranslation()

  const handleDownload = useCallback(() => {
    dispatch(
      downloadFileRequest({
        fileId: id
      })
    )
  }, [dispatch, id])

  useEffect(() => {
    if (src && pdfobject.supportsPDFs) {
      pdfobject.embed(src, `#${ROOT_ID}`)
    }
  }, [src])

  if (!pdfobject.supportsPDFs) {
    return (
      <div className={styles.root}>
        <EmptyList
          className={styles.placeholder}
          icon={<ToastWarningIcon />}
          text={
            <>
              <Trans
                t={t}
                i18nKey="common.pdfViewer.placeholder"
                components={[
                  <Button
                    key={0}
                    variant="underlined"
                    className={styles.downloadButton}
                    onClick={handleDownload}
                  />
                ]}
              />
            </>
          }
        />
      </div>
    )
  }

  return (
    <div className={styles.root}>
      {progress !== 100 ? (
        <FileViewerProgress
          percent={progress}
          label={!!error ? t('common.fileViewer.failure') : undefined}
          status={!!error ? 'exception' : undefined}
        />
      ) : (
        <div className={styles.container} id={ROOT_ID} />
      )}
    </div>
  )
}
