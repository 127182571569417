import {
  BloodGroupsEnum,
  CompanySizeType,
  FilePermissionsIdsEnum,
  AccountTypeNames,
  PlatformInvitationType
} from '@medentee/enums'

import { TToastOptions, EContainerId } from 'App/components/ToastContainer'
import { EStorageUnitsEnum } from 'enums'
import { convertStorageUnitsToBytes } from 'utils/formats'
import { TFilePermissionsName } from 'interfaces/api/cases/casesCloudFilesDTO'

import { TOption } from '../App/components'

export const PRIVATE_PROFILE_TYPE = 'private'

export const SECOND = 1000
export const MINUTE = 1000 * 60

export const DEFAULT_TIME_HELPER = 50
export const SECOND_IN_MINUTE = 60

export const DOT_REGEXP = /^\./

// https://edento.atlassian.net/wiki/spaces/MDT/pages/780140545/Whitelist
export const IMAGE_WHITELIST: string[] = [
  'jpg',
  'jpeg',
  'png',
  'tif',
  'tiff',
  'bmp',
  'dib',
  'gif',
  'webp'
]
export const MODELS_WHITELIST: string[] = ['stl', 'dicom', 'obj', 'ply', 'dcm']

/**
 * TODO:
 * This problem is also related to any double extensions such as `.tar.gz`
 * Due to a problem in the browser engine, ARCHIVE_TAR_WHITELIST extensions were temporarily turned off.
 * This should also be implemented in the task MED-703
 * */
export const ARCHIVE_TAR_WHITELIST: string[] = []

export const ARCHIVE_WHITELIST: string[] = ['7z', 's7z', 'apk', 'rar', 'zip', 'zipx']
export const DOCUMENTS_WHITELIST: string[] = ['pdf', 'doc', 'docx', 'txt', 'pages']
export const VIDEO_WHITELIST: string[] = ['mp4', 'webm', 'mov', 'wmv', 'avi', 'avchd', 'flv', 'mkv']
export const AUDIO_WHITELIST: string[] = [
  'mp3',
  'wav',
  'aiff',
  'pcm',
  'flac',
  'alac',
  'wma',
  'aac',
  'ogg'
]
export const SPREAD_WHITELIST: string[] = ['xls', 'xlsx', 'csv', 'numbers']
export const PRESENTATION_WHITELIST: string[] = [
  'ppt',
  'pptx',
  'ppsx',
  'pptm',
  'potx',
  'key',
  'pez'
]

export const WHITELIST_FILES: string[] = [
  ...IMAGE_WHITELIST,
  ...MODELS_WHITELIST,
  ...ARCHIVE_TAR_WHITELIST,
  ...ARCHIVE_WHITELIST,
  ...DOCUMENTS_WHITELIST,
  ...VIDEO_WHITELIST,
  ...AUDIO_WHITELIST,
  ...SPREAD_WHITELIST,
  ...PRESENTATION_WHITELIST
]

export const WHITELIST_FILES_DOT: string[] = WHITELIST_FILES.map(
  (extension: string) => `.${extension}`
)

export const PERMISSION_NAME_MAP = new Map<FilePermissionsIdsEnum, () => TFilePermissionsName>()
  .set(FilePermissionsIdsEnum.OPEN, () => 'OPEN')
  .set(FilePermissionsIdsEnum.PREVIEW, () => 'PREVIEW')
  .set(FilePermissionsIdsEnum.OWNER, () => 'OWNER')

export const PERMISSION_VIEW: FilePermissionsIdsEnum[] = [
  FilePermissionsIdsEnum.OPEN,
  FilePermissionsIdsEnum.PREVIEW
]

export const PERMISSION_FULL_VIEW: FilePermissionsIdsEnum[] = [
  FilePermissionsIdsEnum.OPEN,
  FilePermissionsIdsEnum.PREVIEW,
  FilePermissionsIdsEnum.OWNER
]

export enum ESessionsStorageKeys {
  ERROR_COUNT = 'e_b_c',
  REDIRECT_TOAST_INFO = 'r_t_i'
}

export enum EInvitationType {
  RECEIVED = 'RECEIVED',
  SENT = 'SENT'
}

export enum EInvitationAction {
  REJECT = 'REJECT',
  WITHDRAW = 'WITHDRAW',
  REMOVE = 'REMOVE'
}

export enum EHistoryType {
  AROUND = 'around',
  AFTER = 'after',
  BEFORE = 'before'
}

export const TOAST_AUTO_CLOSE_DELAY = 5000

export const toastDefaultOptions: TToastOptions = {
  containerId: EContainerId.ALERTS,
  autoClose: TOAST_AUTO_CLOSE_DELAY
}

export const enterKeyCodes = ['Enter', 13]
export const deleteKeyCodes = [8, 46]

export const DEFAULT_THROTTLE_MS = 500
export const extensionRegExp = /\.\w+$/

export const FILE_ESCAPE_SYMBOLS = '\\/?*:|<>!\'"'

export const MIN_LINK_LENGTH = 4
export const MAX_LINK_LENGTH = 2000

export const DEFAULT_CHAT_MESSAGES_LIMIT = 50

export const NOTIFICATION_COUNTER_MAX_VALUE = 99

export const SHOW_SECOND_MODAL_ERROR = 5
export const SHOW_SECOND_MESSAGE_PUSH_UP = 5

export const MAX_HEADLINE_LENGTH = 180

export const MAX_LENGTH_5000 = 5000
export const MAX_LENGTH_1000 = 1000
export const MAX_LENGTH_500 = 500
export const MAX_LENGTH_300 = 300
export const MAX_LENGTH_255 = 255
export const MAX_LENGTH_200 = 200
export const MAX_LENGTH_150 = 150
export const MAX_LENGTH_120 = 120
export const MAX_LENGTH_100 = 100
export const MAX_LENGTH_50 = 50
export const MAX_LENGTH_45 = 45
export const MIN_LENGTH_1 = 1

export const MAX_CATEGORIES_ALLOWED = 30

export const MAX_BUSINESS_ACCOUNTS_MANAGED = 6
export const MAX_ADMIN_ACCOUNTS_MANAGED = 5

export const FILE_HISTORY_DEFAULT_SHOW_BY = 30
export const CHAT_ROOMS_DEFAULT_SHOW_BY = 20

export const MAX_DRAFT_LENGTH = 50000

export const COMPANY_SIZE_TYPE_MAP: { [key in CompanySizeType]: string } = {
  [CompanySizeType.UP_TO_5]: '1-5',
  [CompanySizeType.FROM_5_TO_15]: '5-15',
  [CompanySizeType.FROM_15_TO_30]: '15-30',
  [CompanySizeType.MORE_THAN_30]: '30+'
}

export const COMPANY_SIZE_TYPE: TOption[] = Object.values(CompanySizeType).map((type) => ({
  value: type,
  label: COMPANY_SIZE_TYPE_MAP[type]
}))

export type TBloodGroupTypeOption = {
  value: BloodGroupsEnum
}

export const BLOOD_GROUP_TYPE: TBloodGroupTypeOption[] = Object.values(BloodGroupsEnum).map(
  (group) => ({
    value: group
  })
)

export const INVITATION_TYPE_MAP = new Map<AccountTypeNames, PlatformInvitationType>()
  .set(AccountTypeNames.PROFESSIONAL, PlatformInvitationType.CONTACT)
  .set(AccountTypeNames.BUSINESS, PlatformInvitationType.STAFF)

export const DEFAULT_ROUTE = '/chat/dialog'

export const ADVERTS_WRAPPER_ID = 'advertsWrapper'

export const MIN_PICKER_DATE = new Date('1900-01-01')

export const DEFAULT_SKELETON_IDS_LENGTH = 5
export const LIST_SKELETON_IDS_LENGTH = 10

export enum EChatTypeMap {
  DIALOGUE = 'dialog',
  GROUP = 'dialog',
  CASE_GROUP = 'group'
}

export const CHAT_PATH = '/chat/:chatType?/:chatId?'
export const CREATE_GROUP_CHAT_PATH = '/chat/:chatType/create'
export const EDIT_GROUP_CHAT_PATH = '/chat/:chatType/:chatId/edit'

export const REFRESH_UPLOADING_SESSION_DELAY = 1000 * 60 * 50

export const ANTD_TABLE_SELECTION_COLUMN_CLASS_NAME = 'ant-table-selection-column'

/**
 * Jitsi the supported remote video resolutions. The values are currently based on
 * available simulcast layers.
 */
export const JITSI_VIDEO_QUALITY_LEVELS = {
  ULTRA: 2160,
  HIGH: 720,
  STANDARD: 360,
  LOW: 180
}

export const SYSTEM_USER_ID = '0'

export const MAX_VOICE_ROOM_PARTICIPANTS = 30
export const MAX_GROUP_CHAT_FILE_SIZE = convertStorageUnitsToBytes(10, EStorageUnitsEnum.MB)
export const MAX_GROUP_CHAT_PARTICIPANTS = 1000

export const MAX_SHOWCASE_FILES_SIZE = convertStorageUnitsToBytes(80, EStorageUnitsEnum.MB)
export const MAX_SHOWCASE_FILES_AMOUNT = 10

export const TOUCH_SENSOR_DELAY = 350
export const TOUCH_SENSOR_TOLERANCE = 200

export const SUPPORT_EMAIL = 'support@medentee.com'
export const INFO_EMAIL = 'info@medentee.com'

export const MAX_DEPARTMENT_LEAD_ALLOWED = 1

export const MAX_HOURS_TEMPORARY_MESSAGE_ACTION_ALLOWED = 2

export const CREATE_BUSINESS_ACCOUNT_MODAL_WIDTH = 530

export const BUSINESS_ACCOUNT_LIMIT = 3

export const RECOMMENDED_BROADCAST_TOOL_LINK = 'https://restream.io/'

export const GUEST_USER_PROFILE_SEARCH_QUERY_KEY = 'guest'

export const DOWNLOAD_IFRAME_NAME = 'download_iframe'

export const AVOIDED_EMOJI_VERSION = '12.0'

export const MAX_MESSAGE_REACTION_ACCOUNTS_SHOW = 2

export const MAX_MESSAGE_REACTIONS = 20
